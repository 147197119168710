import { handleActions, createAction } from 'redux-actions';
import { ReestrStateType, ReestrActionsType } from '../../types/reestr';
import api from '../../api';

const initialState: ReestrStateType = {
    fromStop: {
        items: [],
        query: '',
        loading: false,
        selected: null
    },
    toStop: {
        items: [],
        query: '',
        loading: false,
        selected: null
    },
    schedule: {
        loading: false,
        data: null
    }
}

const reestrActions: ReestrActionsType = {
    searchSuggest: (v, type) => {
        return dispatch => {
            dispatch({
                type: 'LOAD_STATION_SUGGEST_START',
                payload: {type}
            })
            api.reestr.stationsSuggest(v)
                .then(({ data }) => {
                    dispatch({
                        type: 'LOAD_STATION_SUGGEST',
                        payload: {
                            type: type,
                            values: {
                                items: data,
                                loading: false
                            }
                        }
                    })
                })
                .catch(e => {
                    dispatch({
                        type: 'LOAD_STATION_SUGGEST',
                        payload: {
                            type: type,
                            values: {
                                error: e,
                                loading: false,
                                items: null
                            }
                        }
                    })
                })
        }
    },
    onChange: createAction('SELECT_SUGGEST', (value, type) => ({value, type})),
    loadSchedule: (from, to) => {
        return dispatch => {
            dispatch({
                type: 'LOAD_SCHEDULE_START'
            })
            api.reestr.schedule(from, to)
                .then(({data}) => dispatch({
                        type: 'LOAD_SCHEDULE',
                        payload: {
                            data,
                            loading: false
                        }
                    })
                )
                .catch(e => dispatch({
                    type: 'LOAD_SCHEDULE',
                    payload: {
                        error: e,
                        loading: false
                    }
                }))
        }
    }
}

const reestrReducer = handleActions(
    {
        'LOAD_SCHEDULE_START': state => ({
            ...state,
            schedule: {
                ...state.schedule,
                loading: true
            }
        }),
        'LOAD_SCHEDULE': (state, {payload}) => ({
            ...state,
            schedule: {
                ...state.schedule,
                ...payload
            }
        }),
        'LOAD_STATION_SUGGEST': (state, {payload: {type, values}}) => ({
            ...state,
            [type]: {
                ...state[type],
                ...values,
                loading: false
            }
        }),
        'LOAD_STATION_SUGGEST_START': (state, {payload: {type}}) => ({
            ...state,
            [type]: {
                ...state[type],
                loading: true,
                items: null,
                error: false
            }
        }),
        [reestrActions.onChange]: (state, {payload: {value, type}}) => ({
            ...state,
            [type]: {
                ...state[type],
                selected: value
            }
        })
    },
    initialState
)

export {
    reestrActions,
    reestrReducer
}