import React from 'react';
import Report from '../modules/BI/Report';
import Morda from '../components/bi/Morda';
import { Layout } from 'antd';
import {withRouter, Route} from 'react-router-dom';

const { Content } = Layout;

const BI = ({match}) => {
    return <Content
        style={{
            // margin: '24px 16px',
            padding: 24,
            background: '#fff',
            minHeight: 280,
        }}
    >
        <Route path={`${match.url}/`} exact component={Morda} />
        <Route path={`${match.url}/report`} component={Report} />
    </Content>
}

export default withRouter(BI);