import React, { useState } from 'react';
import {Modal, Checkbox, Input, Form, Popconfirm} from 'antd';
import SelectFilter from '../../components/bi/Filters/SelectFilter';
import Cube from '../../utils/cube';
import './filters.scss';
import 'antd/dist/antd.css'

const filtersTypes = [
    {
        dimension: 'Trips.serviceId',
        operator: 'equals',
        values: [],
        title: 'По перевозчику',
        placeholder: 'Любой перевозчик',
        type: 'list',
        group: '',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Trips', 'partnerId', 'serviceId', 'serviceName');
            return data.filter(d => d.serviceId > 0).map(d => ({
                value: d.serviceId, title: `${d.serviceId}_${d.serviceName}`,
                partnerId: d.partnerId,
            }));
        }
    },
    {
        dimension: 'Trips.partnerId',
        operator: 'equals',
        values: [],
        title: 'По партнёру',
        group: '',
        placeholder: 'Любой партнёр',
        type: 'list',
        loadItems: async() => {
            const data = await Cube.getDimensions('bq', 'Trips', 'partnerId', 'partnerName');
            return data.filter(d => d.partnerId > 0).map(d => ({
                value: d.partnerId, title: `${d.partnerId}_${d.partnerName}`
            }));
        }
    },
]

export const UserEdit = (props) => {
    const [visibleConfirm, setVisibleConfirm] = useState(false)
    const [formChanged, setFormChanged] = useState(false)

    const getFilterFor = (dimension) => {
        const { filters } = props;
        return filtersTypes.filter(ft => ft.dimension === dimension).map(ft => ({
            ...ft,
            values: filters.filter(f => f.dimension === ft.dimension)[0].values,
            operator: filters.filter(f => f.dimension === ft.dimension)[0].operator,
        }));
    }

    const updateFilterValues = (dimension, values, operator) => {
        const valuesToSave = dimension === 'Trips.partnerId'
            ? (values.length > 0 ? [values[values.length - 1]] : [])
            : values;
        const newValues = props.filters.map(f => ({
            ...f,
            values: f.dimension === dimension ? valuesToSave : f.values,
            operator: f.dimension === dimension ? operator : f.operator,
        }));
        setFormChanged(true);
        props.updateFilters(newValues);
    }

    const updateIsAdmin = (checked) => {
        const userNew = {...props.user, claims: { admin: checked }}
        setFormChanged(true);
        props.updateSelectedUser(userNew)
    }

    const updateField = (field, value) => {
        const userNew = {...props.user, [field]: value}
        setFormChanged(true);
        props.updateSelectedUser(userNew)
    }
    
    const isAdminChecked = props?.user?.claims?.admin;

    const onConfirm = () => {
        setVisibleConfirm(false);
        setFormChanged(false);
        props.onCancel();
    }

    const onConfirmCancel = () => {
        setVisibleConfirm(false);
    }

    const onModalCancel = () => {
        if (formChanged) {
            setVisibleConfirm(true);
        }
        else {
            setFormChanged(false);
            props.onCancel();
        }
    }

    const onModalOk = () => {
        if (props.user.name && (props.user.phone || props.user.email)) {
            setFormChanged(false);
            props.onOk();
        }
    }

    return (
        <Modal centered
               title={props.title}
               visible={props.visible}
               onOk={onModalOk}
               onCancel={onModalCancel}>

            <Popconfirm
                title={"Данные будут потеряны, Продолжить?"}
                visible={!!visibleConfirm}
                onConfirm={onConfirm}
                onCancel={onConfirmCancel}
                arrowPointAtCenter={true}
            >
                <div style={{position: "fixed", left: "50%", top: "50%"}}/>
            </Popconfirm>
            <Form
                id="user-editing-form"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
            >
                <Form.Item
                    label="Имя"
                    name="name"
                >
                    <Input value={props?.user?.name}
                           onChange={(e) => updateField("name", e.target.value) }/>
                </Form.Item>
                <Form.Item
                    label="Почта"
                    name="email"
                >
                    <Input value={props?.user?.email}
                           onChange={(e) => updateField("email", e.target.value) }/>
                </Form.Item>
                <Form.Item
                    label="Телефон"
                    name="phone"
                >
                    <Input value={props?.user?.phone}
                           onChange={(e) => updateField("phone", e.target.value) }/>
                </Form.Item>
                <Form.Item
                    label="Администратор"
                    name="isAdmin"
                >
                    <Checkbox
                        onChange={({ target: { checked } }) => updateIsAdmin(checked)}
                        checked={isAdminChecked}
                    />
                </Form.Item>

                {getFilterFor('Trips.partnerId').map(f => (
                    <Form.Item
                        label={f.title}
                        name={f.dimension}
                    >
                         <SelectFilter
                            context={props.filters}
                            style={{marginRight: 10, marginBottom: 5, marginTop: 5, width: 'inherit'}}
                            key={f.dimension}
                            placeholder={f.placeholder}
                            loadItems={f.loadItems}
                            selected={f.values}
                            onChange={(v, op) => updateFilterValues(f.dimension, v, op)}
                            operator={f.operator}
                            id={f.dimension}
                            type={f.type}
                            max={f.max}
                            min={f.min}
                            units={f.units}
                        />
                    </Form.Item>
                    )
                )}
                {!isAdminChecked && getFilterFor('Trips.serviceId').map(f => (
                        <Form.Item
                            label={f.title}
                            name={f.dimension}
                        >
                            <SelectFilter
                                context={props.filters}
                                style={{marginRight: 10, marginBottom: 5, marginTop: 5, width: 'inherit'}}
                                key={f.dimension}
                                placeholder={f.placeholder}
                                loadItems={f.loadItems}
                                selected={f.values}
                                onChange={(v, op) => updateFilterValues(f.dimension, v, op)}
                                operator={f.operator}
                                id={f.dimension}
                                type={f.type}
                                max={f.max}
                                min={f.min}
                                units={f.units}
                            />
                        </Form.Item>
                    )
                )}

            </Form>
        </Modal>
    )
}
