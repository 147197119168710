import { cubeJsApi } from '../../config';
import { globalUsers } from '../../queries'
import { cleanUpKeys } from '../../utils/format';

export const loadUsersData = (userClaims) => {
    return new Promise((resolve, reject) => {
        cubeJsApi('pg')
            .then(cube => {
                cube.load(globalUsers())
                    .then(({ loadResponse: { data } }) => {
                        resolve(cleanUpKeys(data).filter(
                            ({ claims }) =>
                                !claims.disable && userClaims.admin &&
                                ( userClaims.franchiseeId ? userClaims.franchiseeId === claims.franchiseeId : true )
                            ))
                    })
                    .catch(reject);
            })
            .catch(reject);
    })
}
