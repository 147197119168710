import React from 'react';
import { Table } from 'antd';
import { formatNumber } from '../../../../utils/format';

const ShareTable = ({activeDirection}) => {
    const { overworkingExtras } = activeDirection;
    const { config, overworked } = overworkingExtras;
    var overWorkingSummary = Object.keys(config.ranges)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .map(range => {
            const overworkedItem = overworked[range];
            return {
                startRange: parseInt(range),
                penalty: config.ranges[range].penalty,
                ...overworkedItem,
                tripsCount: overworkedItem.trips.length,
                overShare: 100 * overworkedItem.minutes / overworkingExtras.total_duration
            };
        })
    overWorkingSummary = overWorkingSummary.map((o, i) => ({
        ...o,
        nextRange: (overWorkingSummary[i + 1] || {}).startRange
    }))
    return <Table
        dataSource={overWorkingSummary}
        rowKey="startRange"
        pagination={false}
    >
        <Table.Column
            title="Размер переработки"
            dataIndex="startRange"
            render={(_, r) => (
                r.startRange > 0 ? `от ${r.startRange / 60} `
                    + (r.nextRange ? '' : ' часов') : ''
            )
                + (r.nextRange ? `до ${r.nextRange / 60} ${r.nextRange / 60 > 1 ? 'часов' : 'часа'}` : '')}
        />
        <Table.Column
            title="Количество переработанных минут"
            dataIndex="minutes"
            render={(v, r) => <div>
                {v} мин.
            <strong>{r.overShare > 0 ? ` — ${formatNumber(
                    r.overShare, 0
                )} % от всех рейсов` : ''}</strong>
            </div>}
        />
        <Table.Column
            title="Размер штрафа"
            dataIndex="foul"
            render={(v, r) => <div>
                <span style={{ fontSize: 28 }}>{v}</span>
                <span style={{ color: '#aaa' }}>{" "}= {formatNumber(r.overShare, 0)}% x {r.penalty}</span>
            </div>}
        />
    </Table>
}

export default ShareTable;