import { cubeJsApi } from '../../config';
import { directionRating, feedbackDetails } from '../../queries'
import moment from 'moment';

const cleanUpKeys = o => {
    const newObject = {};
    Object.keys(o).forEach(k => {
        const newKey = k.split('.')[1];
        newObject[newKey] = o[k];
    })
    return newObject
}

export const loadFeedbackData = async (directionId, year, isoWeek, deep) => {
    const weekStart = moment({year: year});
    weekStart.isoWeek(isoWeek).isoWeekday(1);
    const weekEnd = weekStart.clone();
    weekEnd.isoWeekday(7);
    weekStart.subtract(deep, 'weeks');
    return new Promise(async (resolve, reject) => {
        try {
            const cube = await cubeJsApi("bq");
            const {loadResponse: {data}} = await cube.load(feedbackDetails(weekStart, weekEnd, directionId));
            resolve(data.map(d => {
                return {...cleanUpKeys(d)}
            }))
        } catch (e) {
            reject(e);
        }
    })
}

export const loadDirectionData = directionId => {
    return new Promise((resolve, reject) => {
        cubeJsApi('pg')
            .then(cube => {
                cube.load(directionRating(directionId))
                    .then(({ loadResponse: { data } }) => {
                        resolve(data.sort((a, b) => {
                            const dateA = a['DirectionRating.year'] * 100 + a['DirectionRating.isoWeek'];
                            const dateB = b['DirectionRating.year'] * 100 + b['DirectionRating.isoWeek'];
                            if (dateA > dateB) {
                                return 1;
                            } else if (dateA < dateB) {
                                return -1;
                            }
                            return 0;
                        }).map(d => {
                            const direction = cleanUpKeys(d);
                            const year = direction.year;
                            const isoWeek = direction.isoWeek;
                            const startDate = moment({
                                year
                            });
                            const endDate = startDate.clone();
                            startDate.isoWeek(isoWeek).isoWeekday(1);
                            endDate.isoWeek(isoWeek).isoWeekday(7);

                            return {
                                ...direction,
                                rating: parseInt(direction.rating),
                                feedbackFoul: parseInt(direction.feedbackFoul),
                                cancellationsFoul: parseInt(direction.cancellationsFoul),
                                overworkingFoul: parseInt(direction.overworkingFoul),
                                feedbackExtra: JSON.parse(direction.feedbackExtra),
                                overworkingExtras: JSON.parse(direction.overworkingExtras),
                                cancellationsExtras: JSON.parse(direction.cancellationsExtras),
                                delayExtra: JSON.parse(direction.delayExtra),
                                delayFoul: parseInt(direction.delayFoul),
                                averageRating: parseInt(direction.averageRating),
                                startDate, 
                                endDate
                            }
                        }))
                    })
                    .catch(reject);
            })
            .catch(reject);
    })
}