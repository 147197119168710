export type ReportExampleGroupType = {[String]: {
    title: String,
    number: Number
}}

export type ReportExampleType = {
    title: String,
    description: ?String,
    image: String,
    params: {},
    span: ?Number,
    group: String
}

export const groups: Array<ReportExampleGroupType> = {
    logistic: {
        title: 'Логистика',
        number: 0
    },
    marketing: {
        title: 'Маркетинг и продажи',
        number: 1
    },
    quality: {
        title: 'Качество',
        number: 2
    }
}

const reports: Array<ReportExampleType> = [
    {
        title: 'Процент неявок по способу оплаты',
        description: 'Как часто не являются на рейс пассажиры, которые оплатили билет онлайн? А как часто остальные?',
        image: 'cancel-payment-source.png',
        group: groups.logistic,
        params: {
            metrics: ["lateCancellationsShare"],
            groupings: ["date", "paymentSource"],
            graphType: 'line'
        }
    },
    {
        title: 'Выручка по источникам',
        description: 'Посмотреть, сколько пассажиров и выручки приходится на сайт и приложение атласа, операторов, водителей',
        image: 'revenue-source.png',
        group: groups.marketing,
        params: {
            metrics: ["totalRevenue"],
            groupings: ["date", "source"],
            graphType: 'area'
        }
    },
    {
        title: 'Процент отмен по водителям',
        description: 'Посмотреть, у какого водителя больше всего неявившехся пассажиров на рейс',
        image: 'noshow-driver.png',
        group: groups.quality,
        params: {
            metrics: ["pax", "lateCancellationsCount", "lateCancellationsShare"],
            groupings: ["driver"],
            graphType: 'table'
        }
    },
    {
        title: 'Новые vs вернувшиеся пассажиры',
        description: 'Сколько новых пассажиров вы провезли?',
        image: 'new-old-count.png',
        group: groups.marketing,
        params: {
            metrics: ["pax"],
            groupings: ["date", "isNewPass"],
            graphType: 'area'
        }
    },
    {
        title: 'Разбивка по способам оплаты',
        description: 'Каким образом чаще всего оплачивают ваши билеты?',
        image: 'passenger-source.png',
        group: groups.marketing,
        params: {
            metrics: ["pax"],
            groupings: ["date", "paymentSource"],
            graphType: 'area'
        }
    },
    {
        title: 'Пассажиропоток по водителям',
        description: 'Сколько пассажиров провезли ваши водители? Сколько выручки?',
        image: 'pass-driver.png',
        group: groups.logistic,
        params: {
            metrics: ["pax", "totalRevenue"],
            groupings: ["date", "driver"],
            graphType: 'table'
        }
    },
    {
        title: 'Средняя выручка на рейс по дням недели',
        description: 'В какие дни недели ваши рейсы привозят больше всего выручки?',
        image: 'avg-revenue-per-trip.png',
        group: groups.logistic,
        params: {
            metrics: ["revenuePerTrip"],
            groupings: ["weekDay"],
            graphType: 'bar'
        }
    },
    {
        title: 'Средняя стоимость билета по источникам',
        description: 'Сколько в среднем стоит билет на ваши рейсы, в зависимости от источника',
        image: 'avg-price-source.png',
        group: groups.marketing,
        params: {
            metrics: ["avgTicketPrice"],
            groupings: ["date", "source"],
            graphType: 'line'
        }
    },
    {
        title: 'Заполняемость по дням недели',
        description: 'В какие дни недели заполняемость на вашем направлении выше всего?',
        image: 'fillshare-weekday.png',
        group: groups.logistic,
        params: {
            metrics: ["fillShare"],
            groupings: ["weekDay"],
            graphType: "bar"
        }
    }
]

export default reports.sort((a, b) => {
    return Math.floor(Math.random() * 2.99) - 1
});