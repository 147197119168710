const feedbackRating = (startDate, endDate, directionId) => ({
    measures: [
        "Feedback.rating"
    ],
    timeDimensions: [
        {
            dimension: "Feedback.utcDatetime",
            granularity: "day",
            dateRange: [startDate, endDate]
        }
    ],
    dimensions: [
        "Feedback.lineName",
        "Feedback.carName",
        "Feedback.carNum",
        "Feedback.driverId",
        "Feedback.localDatetime",
        "Feedback.driverName",
        "Feedback.isClean",
        "Feedback.isDriverPolite",
        "Feedback.userComment",
        "Feedback.serviceId",
        "Feedback.serviceName",
        "Feedback.userId",
        "Feedback.carId"

    ],
    filters: [
        {
            "dimension": "Feedback.directionId",
            "operator": "equals",
            "values": [
                directionId
            ]
        }
    ],
    order: {
        "Feedback.utcDatetime": 'desc'
    }
})

export default feedbackRating;
