import React from 'react';
import { cleanUpKeys } from '../../utils/format';
import QueryRenderer from '../../utils/QueryRenderer';
import { feedbackDetails } from '../../queries'
import FeedbackList from './feedbackList';
import FeedbackDriversTable from './byDrivers';
import FeedbackCarsTable from './byCars';
// eslint-disable-next-line no-unused-vars
import moment from 'moment';

/**
 * 
 * @param {{
 * directionId: String,
 * startDate: moment.Moment,
 * endDate: moment.Moment
 * }} param0 
 */
const FeedbackCharts = ({ children, directionId, startDate, endDate }) => {
    return <QueryRenderer
        query={feedbackDetails(startDate.format(), endDate.format(), directionId)}
        type="bq"
        render={({ resultSet }) => {
            return React.Children.map(children, chart => (
                React.cloneElement(chart, {data: resultSet && cleanUpKeys(resultSet.loadResponse.data)})
            ))
        }}
    />
}

export default FeedbackCharts;
export {
    FeedbackList, 
    FeedbackDriversTable, 
    FeedbackCarsTable
};