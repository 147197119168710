import React from 'react';
import Direction from '../modules/Direction';
import {Route, Redirect} from 'react-router-dom'

const DirectionRoute = ({match}) => {
    return <div>
        <Route path={`${match.url}`} exact render={() => <Redirect to={match.url} />} />
        <Route path={`${match.url}/:directionId/:year/:isoWeek`} component={Direction} />
    </div>
}

export default DirectionRoute;