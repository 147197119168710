// @flow
import React from 'react';
import { block } from 'bem-cn';
import './itemsList.scss';

export type ItemsListProps = {
    title: String,
    children: React.Component
}

class ItemsList extends React.Component<ItemsListProps> {
    render() {
        const b = block('items-list');
        return <tr className={b()}>
            <td className={b('title')}>
                {this.props.title}:{" "}
            </td>
            <td>{this.props.children}</td>
    </tr>
    }
}

export default ItemsList;