import React from 'react';
import { Select, Spin } from 'antd';
import { ReestrStopComponentType } from '../../types/reestr';

class StopSuggest extends React.Component<ReestrStopComponentType & {
    placeholder: ?String,
    onSearch: (value: String) => void,
    onChange: (value: String) => void
}> {
    loadTimeout = null

    handleSearch(v) {
        if (this.loadTimeout) {
            clearTimeout(this.loadTimeout);
        }
        if (v && v.length > 2) {
            this.loadTimeout = setTimeout(() => {
                this.props.onSearch(v);
            }, 300)
        }
    }
    render() {
        const {items, loading} = this.props;
        return <Select
            value={this.props.selected}
            onChange={this.props.onChange}
            showSearch
            style={{width: '100%'}}
            placeholder={this.props.placeholder}
            notFoundContent={ loading ? <Spin /> : <span>Ничего не найдено</span> }
            onSearch={this.handleSearch.bind(this)}
        >
            {items && items.map(i => (
                <Select.Option
                    key={i.name}
                    value={i.name}
                >
                    {i.name} - <span style={{color: '#aaa'}}>{i.region}</span>
                </Select.Option>
            ))}
        </Select>
    }
}

export default StopSuggest;