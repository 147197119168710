/* eslint-disable jsx-a11y/accessible-emoji */
import * as actions from './reducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Report from '../../components/bi/Report';

const mapStateToProps = ({bi}) => ({
    ...bi,
    reproduce: bi
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Report);