import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './store'
import Auth from './modules/Auth';
import "./App.css";

import { LocaleProvider } from 'antd';
import ru_RU from 'antd/lib/locale-provider/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');


function App() {
  return (
    <Provider store={store}>
      <Router>
        <LocaleProvider locale={ru_RU}><div className="App">
          <Auth />
        </div></LocaleProvider>
      </Router>
    </Provider>
  );
}

export default App;
