import React from 'react';
import Reestr from '../modules/Reestr';
import {Route} from 'react-router-dom'
import {Layout} from 'antd';

const {Content} = Layout;

const ReestrRoute = ({match}) => {
    return <Content
        style={{
            // margin: '24px 16px',
            padding: 24,
            background: '#fff',
            minHeight: 280,
        }}
    >
        <Route path={`${match.url}`} component={Reestr} />
    </Content>
}

export default ReestrRoute;