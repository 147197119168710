import React, { useState } from 'react';
import { Color } from './colors';
import { GraphProps } from './types';
import { formatNumber, format } from '../../../utils/format';

import {
    ResponsiveContainer,
    LineChart, AreaChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip,
    Legend
} from 'recharts';

const LineComponent = (props: GraphProps) => {
    const { 
        data, timeDimensions, dimensions, type, formats, showShares,
        yAxises
    } = props;
    const [ legendFilter, setLegendFilter ] = useState([]);
    const [ hoveredItem, setHoveredItem ] = useState(null);

    var Chart, ChartLine;
    switch(type) {
        case 'area':
            Chart = AreaChart;
            ChartLine = Area;
            break;
        case 'line':
            Chart = LineChart;
            ChartLine = Line;
            break;
        default:
            break;
    }

    const legend = dimensions.map(d => ({
        color: Color.randomColor(d),
        active: !legendFilter.includes(d),
        type: 'line',
        dataKey: d,
        value: d
    }))

    return <ResponsiveContainer
        height={380}
    >
        <Chart
            data={data}
        >
            {timeDimensions.map(d => {
                return <XAxis
                    dataKey={d} key={d}
                />
            })}
            {dimensions
                .filter(d => !legendFilter.includes(d))
                .map((d, i) => {
                    const color = Color.randomColor(d).toString(!hoveredItem || hoveredItem === d);
                    return <ChartLine
                        key={d} 
                        yAxisId={ i > 2 ? (yAxises && yAxises[0]) || dimensions[0] : 
                            (yAxises && yAxises[i]) || dimensions[0] } 
                        dataKey={d}
                        stroke={color}
                        fill={type === 'area' ? color : undefined}
                        strokeWidth={2}
                        type="monotone"
                        isAnimationActive={false}
                        stackId={type === 'area' ? '1' : undefined}
                    />
                })
            }
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis 
                width={100} 
                yAxisId={yAxises ? yAxises[0] : dimensions[0]} 
                orientation="left" 
                tickFormatter={n => format(n, showShares ? 'percent' : 'number')}
                domain={type === 'area' && showShares ? [0, 100] : undefined}
            />
            <YAxis 
                width={100} 
                yAxisId={yAxises ? yAxises[1] : undefined} 
                orientation="right" 
                tickFormatter={formatNumber} 
            />
            <Legend
                payload={legend.map(l => ({...l, color: l.color.toString(l.active)}))}
                onClick={({dataKey}) => {
                    if (legendFilter.includes(dataKey)) {
                        setLegendFilter(legendFilter.filter(l => l !== dataKey))
                    } else {
                        setLegendFilter([...legendFilter, dataKey]);
                    }
                }}
                formatter={(value, payload) => <span style={{
                    opacity: payload.active ? 1 : 0.25,
                    cursor: 'pointer',
                    color: hoveredItem === payload.value ? '#000' : undefined,
                }}>{value}</span>}
                onMouseOver={e => setHoveredItem(e.dataKey)}
                onMouseOut={e => setHoveredItem(null)}
            />
            <Tooltip
                formatter={(v, name) => {
                    var value = v;
                    if (typeof (v) === "number") {
                        value = formatNumber(v)
                    }
                    switch (formats[name]) {
                        case 'percent':
                            value = `${value} %`;
                            break;
                        default:
                            break;
                    }
                    return value
                }}
            />
        </Chart>
    </ResponsiveContainer>
}

export default LineComponent;