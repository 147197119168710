/**
 * 
 * @param {Number} year 
 * @param {Number} isoWeek 
 */
const globalRating = (year, isoWeek) => ({
    measures: [
        "GlobalRating.rating",
        "GlobalRating.averageRating",
        "GlobalRating.feedbackFoul",
        "GlobalRating.cancellationsFoul",
        "GlobalRating.overworkingFoul",
        "GlobalRating.onlineShare"
    ],
    timeDimensions: [],
    dimensions: [
        "GlobalRating.directionName",
        "GlobalRating.directionId",
        "GlobalRating.ratingHistory",
        "GlobalRating.year",
        "GlobalRating.isoWeek",
        "GlobalRating.franchiseeName",
        "GlobalRating.isAvailable"
    ],
    filters: [
        {
            dimension: "GlobalRating.year",
            operator: "equals",
            values: [year.toString()]
        },
        {
            dimension: "GlobalRating.isoWeek",
            operator: "equals",
            values: [isoWeek.toString()]
        }
    ]
})

export default globalRating;