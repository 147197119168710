// @flow
// eslint-disable-next-line no-unused-vars
import moment from 'moment';

export type FormatType = 'number' | 'date' | 'percent';

export const formatNumber = (n: number, digits?: number = 2) => {
    if (n !== null && n !== undefined) {
        if (typeof(n) == String) {
            return n;
        } else {
            return n.toLocaleString(undefined, {maximumFractionDigits: digits});
        }
    } else {
        return '';
    }
}

export const format = (value: any, type: FormatType) => {
    switch (type) {
        case 'date':
            return moment(value).format('DD.MM.YYYY');
        case 'number':
            return formatNumber(value);
        case 'percent':
            return `${formatNumber(value)} %`;
        default:
            return value;
    }
}

export const formatWeekName = (day: Moment) => {
    const day1 = day.clone();
    const day2 = day.clone().add(6, 'days');
    return day1.month() === day2.month() ? 
        `${day1.format('D')} - ${day2.format('D MMMM')}`
        : `${day1.format('D MMMM')} - ${day2.format('D MMMM')}`
}

export const unfoldWeek = (year: number, isoWeek: number): {
    startDate: Moment,
    endDate: Moment
} => {
    const startDate = moment({year});
    startDate.isoWeek(isoWeek).isoWeekday(1);
    const endDate = startDate.clone();
    endDate.isoWeekday(7);
    return {startDate, endDate};
}

export const cleanUpKeys = arr => {
    return arr.map(o => {
        const newObject = {};
        Object.keys(o).forEach(k => {
            const newKey = k.split('.')[1];
            newObject[newKey] = o[k];
        })
        return newObject
    })
}

const hourTitle = number => {
    switch(number % 10) {
        // case 0 && number === 0:
        //     return '';
        case 1:
            return `${number} час`;
        case 2:
            return `${number} часа`;
        case 3:
            return `${number} часа`;
        case 4:
            return `${number} часа`
        default:
            return `${number} часов`; 
    }
}

export const formatMinutes = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const minute = minutes % 60;
    return (hours > 0 ? hourTitle(hours) : '')
        + ` ${minute} минут`
}