import React from 'react';
import {Row, Col, Card, Typography} from 'antd'
import {withRouter} from 'react-router-dom'

const GUTTER = {
    horizontal: 20,
    vertical: 20
}

const Dashboard = ({children}) => {
    return <div>
        <Row gutter={GUTTER.vertical}>
            {children}
        </Row>
    </div>
}

/**
 * 
 * @param {{
 *  title: String,
 *  span: Number,
 *  bordered: Bool
 * }} props 
 */
var DashboardItem = props => {
    const { title, children, span, linkTo, history } = props;
    const bordered = props.bordered === undefined ? false : props.bordered;
    return <Col 
        lg={span} 
        span={24}
        style={{
            marginBottom: GUTTER.horizontal
        }}
    >
        <Card
            size="small"
            bordered={bordered}
            hoverable={!!linkTo}
            onClick={linkTo && (() => history.push(linkTo))}
        >
            {title && <Typography.Title style={{marginBottom: 15}} level={3}>{title}</Typography.Title>}
            <div style={{padding: '10px 0'}}>
                {children}
            </div>
        </Card>
    </Col>
}
DashboardItem = withRouter(DashboardItem);

export {
    Dashboard, DashboardItem
}
