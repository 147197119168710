import React from 'react';
import Reestr from '../../components/reestr/Reestr';


class ReestrModule extends React.Component {
    render() {
        return <Reestr 
            {...this.props}
        />
    }
}

export default ReestrModule;