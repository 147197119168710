import React from 'react';
import { Dashboard, DashboardItem } from '../../../components/dashboard';
import BigNumber from '../../../components/bigNumber';
import {connect} from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';

class Cancellations extends React.Component {
    cancelledPassengers(direction) {
        return direction ? direction.cancellationsExtras.cancelled_passengers : 0;
    }
    totalPassengers(direction) {
        return direction ? direction.cancellationsExtras.total_passengers : 0;
    }
    cancelledShare(direction) {
        return !direction ? 0 : 100 * this.cancelledPassengers(direction) / this.totalPassengers(direction);
    }
    render() {
        const {activeDirection, previousDirection} = this.props;

        return <Dashboard>
            <DashboardItem span={8}>
                <BigNumber
                    reverse={true}
                    title="Отменённых пассажиров"
                    value={this.cancelledPassengers(activeDirection)}
                    prevValue={previousDirection && this.cancelledPassengers(previousDirection)}
                />
            </DashboardItem>
            <DashboardItem span={8}>
                <BigNumber
                    reverse={true}
                    title="% от всех"
                    value={this.cancelledShare(activeDirection)}
                    suffix=" %"
                    prevValue={previousDirection && this.cancelledShare(previousDirection)}
                />
            </DashboardItem>
            <DashboardItem span={8}>
                <BigNumber
                    reverse={true}
                    title="Штрафные баллы"
                    value={activeDirection.cancellationsFoul}
                    prevValue={previousDirection && previousDirection.cancellationsFoul}
                />
            </DashboardItem>
            <DashboardItem span={24} title="Отменённые рейсы">
                <Table
                    dataSource={activeDirection.cancellationsExtras.cancelled_trips}
                    rowKey={r => [r.dep_time, r.line_id].join("-")}
                >
                    <Table.Column 
                        dataIndex="line_name"
                        title="Маршрут"
                    />
                    <Table.Column 
                        dataIndex="dep_time"
                        title="Дата отправления"
                        sorter={(a, b) => moment(a.dep_time).diff(moment(b.dep_time))}
                        render={v => moment.utc(v).utc().format('D MMM в HH:mm')}
                    />
                    <Table.Column 
                        dataIndex="cancelled"
                        sorter={(a, b) => a.cancelled - b.cancelled}
                        title="Пассажиров отменено"
                    />
                </Table>
            </DashboardItem>
        </Dashboard>
    }
}

const mapStateToProps = ({directionReducer}) => directionReducer;
export default connect(mapStateToProps)(Cancellations);