
import React from 'react';
import {connect} from 'react-redux';
import { Dashboard, DashboardItem } from '../../../../components/dashboard';
import BigNumber from '../../../../components/bigNumber';
import { Divider, Table } from 'antd';
import { formatNumber, unfoldWeek } from '../../../../utils/format';
import FeedbackCharts, {
    FeedbackList, 
    FeedbackDriversTable,
    FeedbackCarsTable
} from '../../../../charts/feedback';
import Spinner from '../../../../components/spinner';

class Feedback extends React.Component {
    render() {
        const {activeDirection, previousDirection, directionId} = this.props;
        const {feedbackExtra} = activeDirection;
        const ratingDetails = feedbackExtra && Object.keys((feedbackExtra.config.ranges)).filter(k => {
            return feedbackExtra.details.filter(r => {
                return r.rating === k;
            }).length > 0;
        }).map(k => {
            const config = feedbackExtra.config.ranges[k];
            const rating = feedbackExtra.details.filter(r => {
                return r.rating === k;
            })[0];
            return {...rating, penalty: config.penalty}
        })
        return <div>
            {ratingDetails && <Dashboard>
                <DashboardItem bordered={false} span={8}>
                    <BigNumber 
                        fraction={1}
                        title="Штрафные баллы"
                        reverse={true}
                        value={activeDirection.feedbackFoul}
                        prevValue={previousDirection && previousDirection.feedbackFoul}
                    />
                </DashboardItem>
                <DashboardItem bordered={false} span={8}>
                    <BigNumber 
                        fraction={1}
                        title="Средний отзыв"
                        value={activeDirection.feedbackRating}
                        prevValue={previousDirection && previousDirection.feedbackRating}
                    />
                </DashboardItem>
                <DashboardItem bordered={false} span={8}>
                    {feedbackExtra && <BigNumber 
                        fraction={1}
                        title="Всего отзывов"
                        value={activeDirection.feedbackExtra.details.reduce((a,b) => {
                            return a + b.count
                        }, 0)}
                        prevValue={previousDirection && previousDirection.feedbackExtra && previousDirection.feedbackExtra.details.reduce((a,b) => {
                            return a + b.count
                        }, 0)}
                    />}
                </DashboardItem>
                <Divider />
                <DashboardItem title="Распределение отзывов" bordered={false} span={24}>
                    <Table 
                        dataSource={ratingDetails.sort((a, b) => b.rating - a.rating)}
                        pagination={false}
                        rowKey="rating"
                    >
                        <Table.Column 
                            dataIndex="rating"
                            title="Оценка"
                            render={t => <span style={{fontSize: 30, padding: '0 10px'}}><strong>{t}</strong></span>}
                        />
                        <Table.Column 
                            dataIndex="count"
                            render={(_, r) => <span>
                                <strong>{formatNumber(r.share, 2)}%</strong> ({r.count})
                            </span>}
                            title="Количество отзывов"
                        />
                        <Table.Column 
                            dataIndex="max_share"
                            render={(_, r) => <span>
                                {r.max_share < 100 ? formatNumber(r.max_share, 2) + "%" : "—"}
                                {" "}{r.foul > 0 && <span> — <i>превышение на {formatNumber(r.share / r.max_share, 2)}%</i></span>}
                            </span>}
                            title="Допустимое количество"
                        />
                        <Table.Column 
                            dataIndex="foul"
                            title="Штраф"
                            render={(_, r) => <span>
                                <span style={{fontSize: 28}}>{r.foul}</span>
                                {r.foul > 0 && <span style={{color: '#aaa'}}>{" "}= {formatNumber(r.share / r.max_share, 2)} * {r.penalty}</span>}
                            </span>}
                        />
                    </Table>
                </DashboardItem>
                {/* <Divider /> */}
                <FeedbackCharts 
                    directionId={directionId} 
                    {...unfoldWeek(activeDirection.year, activeDirection.isoWeek)}
                >
                    <FeedbackChartsItems />
                </FeedbackCharts>
            </Dashboard>}
            {!ratingDetails && <div>Отзывов не было</div>}
        </div>
    }
}

const FeedbackChartsItems = ({data}) => {
    return <div>
        <DashboardItem span={24} bordered={false} title="Рейтинг водителей">
            <Spinner data={data}>
                <FeedbackDriversTable data={data} />
            </Spinner>
        </DashboardItem>
        <DashboardItem span={24} bordered={false} title="Рейтинг машин">
            <Spinner data={data}>
                <FeedbackCarsTable data={data} />
            </Spinner>
        </DashboardItem>
        <DashboardItem span={24} bordered={false} title="Все отзывы">
            <Spinner data={data}>
                <FeedbackList data={data} />
            </Spinner>
        </DashboardItem>
    </div>
}

const mapStateToProps = ({directionReducer}) => directionReducer;

export default connect(mapStateToProps)(Feedback);