import React from 'react';
import _ from 'lodash'
import {FiltersComponentTypes} from './types';
import { Button, Icon, Dropdown, Menu } from 'antd';
import SelectFilter from './SelectFilter';
import filtersTypes from './filterTypes';
import {FilterItemType} from './filterTypes';
import {block} from 'bem-cn';
import './filters.scss';

const FiltersMenu = (props: {
    items: Array<{dimension: String, title: String}>,
    onAdd: (dimension: any) => void
}) => {
    const {items, onAdd} = props;
    const addItem = (dimenstion: string) => {
        const item = items.find(item => item.dimension === dimenstion);
        onAdd(item);
    }
    const groups = _.groupBy(items, 'group');
    const groupNames = Object.keys(groups);
    return <Menu
        className='ant-dropdown-menu'
        onClick={({key}) => addItem(key)}
        style={{minWidth: 200}}
    >
        {groupNames.length === 1 && items.map(m => <Menu.Item
            className="ant-dropdown-menu-item"
            key={m.dimension}
        >
            {m.title}
        </Menu.Item>)}
        {groupNames.length > 1 && groupNames.map(g => <Menu.SubMenu
            key={g}
            title={g}
        >
            {groups[g].map(i => <Menu.Item
                key={i.dimension}
                className="ant-dropdown-menu-item"
            >
                {i.title}
            </Menu.Item>)}
        </Menu.SubMenu>)}
    </Menu>
}

class Filters extends React.Component<FiltersComponentTypes> {
    enabledFilters() {
        const {filters} = this.props;
        return filtersTypes.filter(ft => filters.map(f => f.dimension).includes(ft.dimension)).map(ft => ({
            ...ft,
            values: filters.filter(f => f.dimension === ft.dimension)[0].values,
            operator: filters.filter(f => f.dimension === ft.dimension)[0].operator,
        }));
    }
    unusedFilters() {
        const {filters} = this.props;
        return filtersTypes.filter(ft => !filters.map(f => f.dimension).includes(ft.dimension));
    }
    updateFilterValues(dimension, values, operator) {
        const newValues = this.props.filters.map(f => ({
            ...f,
            values: f.dimension === dimension ? values : f.values,
            operator: f.dimension === dimension ? operator : f.operator,
        }));
        this.props.updateFilters(newValues);
    }
    addFilter(filter: FilterItemType) {
        const filters = [
            ...this.props.filters,
            {
                dimension: filter.dimension,
                operator: filter.operator,
                values: filter.values,
            }
        ]
        this.props.updateFilters(filters);
    }
    deleteItem(dimension: String) {
        this.props.updateFilters(this.props.filters.filter(f => {
            return f.dimension !== dimension
        }))
    }
    render() {
        const b = block('filters');
        return <div className={b()}>
            {this.enabledFilters().map(f => (
                <SelectFilter 
                    context={this.props.filters}
                    onDelete={() => this.deleteItem(f.dimension)}
                    style={{marginRight: 10, marginBottom: 5, marginTop: 5, width: 'inherit'}}
                    key={f.dimension}
                    placeholder={f.placeholder} 
                    loadItems={f.loadItems}
                    selected={f.values}
                    onChange={(v, op) => this.updateFilterValues(f.dimension, v, op)}
                    operator={f.operator}
                    id={f.dimension}
                    type={f.type}
                    max={f.max}
                    min={f.min}
                    units={f.units}
                />
            ))}
            <Dropdown
                overlay={<FiltersMenu items={this.unusedFilters()} onAdd={item => this.addFilter(item)} />}
            >
                <Button
                    type="link"
                >
                    Добавить фильтр
                    <Icon type="down" />
                </Button>
            </Dropdown>
        </div>
    }
}

export default Filters;