/**
 * 
 * @param {String} phone
 */
const globalUsers = () => ({
    measures: [
    ],
    timeDimensions: [],
    dimensions: [
        "GlobalUsers.id",
        "GlobalUsers.email",
        "GlobalUsers.name",
        "GlobalUsers.claims",
        "GlobalUsers.phone",
        "GlobalUsers.isAvailable"
    ],
    filters: [
    ]
})

export default globalUsers;