import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from './modules/rootReducer';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(
            thunk,
            // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    )
);

if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./modules/rootReducer', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

export default store;