import { combineReducers } from 'redux';
import { directionReducer } from './Direction/reducer.js'
import { usersReducer } from './Users/reducer.js'
import { authReducer } from './Auth/reducer';
import { reestrReducer } from './Reestr/reducer';
import biReducer from './BI/reducer';


export default combineReducers({
    usersReducer,
    directionReducer,
    authReducer,
    bi: biReducer,
    reestr: reestrReducer
})
