import React from 'react';
import { ScheduleComponentType } from '../../../types/reestr';
import { block } from 'bem-cn';
import './timeline.scss';
import moment from 'moment';
import Draggable from 'react-draggable';

export type TimelineState = {
    width: ?Number,
    left: ?Number
};

class Timeline extends React.Component<ScheduleComponentType> {
    render() {
        const b = block('timeline');
        const {data} = this.props;
        return <div className={b()}>
            <Draggable
                axis="x"
                // bounds="parent"
            >
                <div className={b('container').toString()} ref={this.setContainerRef}>
                    <div className={b('header').toString()}>
                        {[...Array(24).keys()].map(i => (
                            <div className={b('header_time').toString()} key={i}>
                                {moment({ hour: i }).format('HH:mm')}
                            </div>
                        ))}
                    </div>
                    { data && data.map(i => (
                        <div>ok</div>
                    )) }
                </div>
            </Draggable>
            <div className={b('sidebar').toString()}>
                Сайдбар?
            </div>
        </div >

    }
}

export default Timeline;