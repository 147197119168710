import React from 'react';
import GeneralRating from './general';
import Feedback from './feedback';
import Overworking from './overworking';
import Cancellations from './cancellations';
import Delay from './delay';
import {Route, withRouter} from 'react-router-dom';

const TEST_DATA = [
    {
        foul: "Негативные отзывы пассажиров",
        link: "feedback",
        penalty: 8
    },
    {
        foul: "Опоздания машин",
        link: "otp",
        penalty: 3
    },
    {
        foul: "Несоблюдение режима ТиО",
        link: "overworking",
        penalty: 20
    },
    {
        foul: "Жалобы на а/с или печку",
        link: "temperature",
        penalty: 1
    }
]


const Fouls = ({match}) => {
    return <div>
        <Route path={`${match.url}`} exact render={() => <GeneralRating data={TEST_DATA} />} />
        <Route path={`${match.url}/feedback`} component={Feedback} />
        <Route path={`${match.url}/overworking`} component={Overworking} />
        <Route path={`${match.url}/cancellations`} component={Cancellations} />
        <Route path={`${match.url}/delay`} component={Delay} />
    </div>
}

export default withRouter(Fouls);