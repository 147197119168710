import React from 'react';
import Body from '../pages/Body';
import DirectionRoute from './direction';
import BI from './bi';
import Top from './top';
import Reestr from './reestr';
import UsersRoute from './users';
import {Route, Redirect} from 'react-router-dom'
import { useSelector } from "react-redux";

const RootRoute = ({match}) => {
    const userClaims = useSelector( ({ authReducer: { userClaims }}) => userClaims )
    return <Body>
        <Route exact path="/" render={() => <Redirect to="/top"/>}/>
        <Route path={`${match.url}bi`} component={BI}/>
        <Route path={`${match.url}reestr`} component={Reestr}/>
        <Route path={`${match.url}direction`} component={DirectionRoute}/>
        <Route path={`${match.url}top`} component={Top}/>
        {
            userClaims.admin
                ? <Route path={`${match.url}users`} component={UsersRoute}/>
                : <Route path={`${match.url}users`} render={() => <Redirect to="/top"/>}/>
        }
    </Body>
}

export default RootRoute;