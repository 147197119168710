// @flow
/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { Typography, Select, Button, Icon } from 'antd';
import ItemsList from '../ItemsList';
import Metrics from '../Metrics';
import Groupings from '../Groupings';
import DateRange from '../DateRange';
import Graph from '../Graph';
import Filters from '../Filters';
import {ReportTypes} from './types';
import {block} from 'bem-cn';
import Cube from '../../../utils/cube';
import './report.scss';

class Report extends React.Component<ReportTypes> {
    state = {
        data: null,
        loading: false,
        dirty: true
    }
    onChange(key, value) {
        this.props.updateParams(key, value);
        this.setState({dirty: true});
    }
    async loadData() {
        this.setState({loading: true})
        const {dateRange, dateParam, groupings, granularity, metrics} = this.props;
        window.yaCounter54412891 && window.yaCounter54412891.reachGoal(
            'run_bi', {
                dateRange, dateParam, groupings, granularity, metrics
            }
        )
        try {
            const {loadResponse} = await Cube.query('bq', this.props.query);
            this.setState({data: loadResponse, loading: false, dirty: false})
        } catch(e) {
            this.setState({
                loading: false,
                error: true
            })
            console.error(e);
        }
    }
    componentDidMount() {
        this.props.restoreDefaults();
    }
    render() {
        const b = block('report');
        return <div className={b()}>
            <Typography.Title style={{marginBottom: 30}}>Бизнес аналитика</Typography.Title>
            <div className={b('settings')}>
                <table><tbody>
                    <ItemsList
                        title="Что посчитать"
                    >
                        <Metrics 
                            onChange={v => this.onChange('metrics', v)}
                            showSharesChanged={v => this.props.updateParams('showShares', v)}
                            showShares={this.props.showShares}
                            selected={this.props.metrics}
                        />
                    </ItemsList>
                    <ItemsList
                        title="Как сгруппировать"
                    >
                        <Groupings 
                            onChange={v => this.onChange('groupings', v)}
                            selected={this.props.groupings}
                        />
                    </ItemsList>
                    <ItemsList
                        title="За какой период"
                    >
                        <DateRange 
                            onChange={v => this.onChange('dateRange', v)}
                            value={this.props.dateRange}
                        />
                        <Select
                            style={{minWidth: 200, marginLeft: 20}}
                            value={this.props.dateParam}
                            onChange={v => this.onChange('dateParam', v)}
                        >
                            <Select.Option value="Trips.depDate">Дата отправления</Select.Option>
                            <Select.Option value="buyDate">Дата покупки</Select.Option>
                        </Select>
                        <Select
                            style={{minWidth: 150, marginLeft: 20}}
                            value={this.props.granularity}
                            onChange={(v) => this.onChange('granularity', v)}
                        >
                            <Select.Option value="day">По дням</Select.Option>
                            <Select.Option value="week">По неделям</Select.Option>
                            <Select.Option value="month">По месяцам</Select.Option>
                        </Select>
                    </ItemsList>
                    <ItemsList
                        title="Отфильтровать"
                    >
                        <Filters 
                            filters={this.props.filters}
                            updateFilters={filters => this.onChange('filters', filters)}
                        />
                    </ItemsList>
                </tbody></table>                
            </div>
            <div className={b('update')}>
                <Button 
                    type="primary"
                    disabled={!this.state.dirty || this.state.loading}
                    onClick={this.loadData.bind(this)}
                >
                    <Icon type="reload" spin={this.state.loading} />
                    Посчитать
                </Button>
            </div>
            <div style={{
                opacity: this.state.loading ? 0.35 : 1
            }}>
                <Graph
                    data={this.state.data}
                    showShares={this.props.showShares}
                    groupings={this.props.groupings}
                />
            </div>
        </div>
    }
}

export default Report