import React from 'react';
import {Typography, Table} from 'antd';
import {Dashboard, DashboardItem} from '../../../components/dashboard';
import {Link} from 'react-router-dom';

const GeneralFouls = ({data}) => {
    return <Dashboard>
        <DashboardItem span={24} bordered={false}>
            <Typography>
                <Typography.Paragraph>
                    Изначально рейтинг каждого партнёра и направления равен 100 баллам. Затем
                    из рейтинга вычитаются штрафы. Например за переработку водителей или опоздания
                    машин.
            </Typography.Paragraph>
                <Typography.Paragraph>
                    На этой страничке можно детально изучить что именно повлияло на рейтинг партнёра
                    или направления за эту неделю.
            </Typography.Paragraph>
                {/* <Typography.Title level={3}>Категории нарушений</Typography.Title> */}
            </Typography>
        </DashboardItem>
        <DashboardItem bordered={false} span={24} title="Категории нарушений">
            <Table
                // bodyStyle={{fontSize: '1.1em'}}
                size="middle"
                pagination={false}
                dataSource={data}
            >
                <Table.Column title="Нарушение" render={(_, r) => <Link to={`rating/${r.link}`}>{r.foul}</Link>} />
                <Table.Column title="Штрафные баллы" dataIndex="penalty" render={v => <strong>{v}</strong>} />
            </Table>
        </DashboardItem>
    </Dashboard>
}

export default GeneralFouls;