import React from 'react';
import { Row, Col, Typography, Button } from 'antd';
import { ReestrStateType } from '../../types/reestr';
import StopSuggest from './StopSuggest';
import Timeline from './Timeline';

class Reestr extends React.Component<ReestrStateType> {
    fromSearchTimeout = null
    toSearchTimeout = null
    handleFromSearch() {

    }
    render() {
        const {fromStop, toStop, schedule} = this.props;
        return <div>
            <Typography.Title>Реестр</Typography.Title>
            <Row gutter={20}>
                <Col span={12}>
                    <StopSuggest 
                        {...this.props.fromStop} 
                        placeholder="Остановка отправления" 
                        onSearch={v => this.props.searchSuggest(v, 'fromStop')}
                        onChange={v => this.props.onChange(v, 'fromStop')}
                    />
                </Col>
                <Col span={12}>
                    <StopSuggest 
                        {...this.props.toStop} 
                        placeholder="Остановка назначения" 
                        onSearch={v => this.props.searchSuggest(v, 'toStop')}
                        onChange={v => this.props.onChange(v, 'toStop')}
                    />
                </Col>
            </Row>
            <Row type="flex" justify="center" style={{marginTop: 20}}>
                <Col>
                    <Button 
                        type="primary"
                        disabled={!fromStop.selected || !toStop.selected}
                        loading={schedule.loading}
                        onClick={() => this.props.loadSchedule(fromStop.selected, toStop.selected)}
                    >
                        Найти
                    </Button>
                </Col>
            </Row>
            <Timeline data={schedule.data} />
        </div>
    }
}

export default Reestr;