import {cubeJsApi} from '../../config';
import {QueryType} from '../../types/cube';

export type ModeType = 'bq' | 'pg' | 'mysql';

export default class Cube {
    static async query(mode: ModeType, query: QueryType) {
        const cube = await cubeJsApi(mode);
        return await cube.load(query);
    }

    static async getDimensions(mode: ModeType, cubeName: String, ...dimensions: Array<String>) {
        const cube = await cubeJsApi(mode);
        const {loadResponse: { data }} = await cube.load({
            dimensions: dimensions.map(dimension => `${cubeName}.${dimension}`)
        })
        const result = data.map(d => {
            return Object.keys(d).reduce((acc, v) => {
                const key = v.split('.')[1];
                acc[key] = d[v];
                return acc;
            }, {})
        })
        return result;
    }
}