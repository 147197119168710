import React from 'react';
import { Table } from 'antd';
import flatten from 'lodash/flatten';
import values from 'lodash/values';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import moment from 'moment';
import { formatMinutes } from '../../../../utils/format';


const TripsTable = ({activeDirection}) => {
    const {overworkingExtras} = activeDirection;
    const trips = reverse(sortBy(
        flatten(values(overworkingExtras.overworked).map(v => v.trips)),
        'overworking'
    ));
    return <Table
        dataSource={trips}
        rowKey="trip_id"
    >
        <Table.Column 
            title="Маршрут"
            dataIndex="line_name"
        />
        <Table.Column 
            title="Дата отправления"
            dataIndex="local_datetime"
            render={(_, r) => moment.utc(r.local_datetime).utc().format('D MMM в HH:mm')}
            sorter={(a, b) => moment(a.local_datetime).diff(b.local_datetime)}
        />
        <Table.Column 
            title="Водитель"
            dataIndex="driver_name"
            filters={uniqBy(trips, 'driver_name').map(t => ({
                text: t.driver_name,
                value: t.driver_name
            }))}
            onFilter={(v, r) => v === r.driver_name}
        />
        <Table.Column 
            title="Отработано часов"
            dataIndex="total_duration"
            render={v => formatMinutes(parseInt(v))}
            sorter={(a, b) => a.total_duration - b.total_duration}
        />
        <Table.Column 
            title="Переработка"
            dataIndex="overworking"
            render={v => formatMinutes(parseInt(v))}
            sorter={(a, b) => a.overworking - b.overworking}
        />
    </Table>
}

export default TripsTable;