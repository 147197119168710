import React from 'react';
import { Typography, Table } from 'antd';
import QueryRenderer from '../../utils/QueryRenderer';
import {globalRating} from '../../queries';
import moment from 'moment';
import {cleanUpKeys, formatWeekName, formatNumber} from '../../utils/format';
import {Link} from 'react-router-dom';
import WeeksLine from '../../components/weeksLine';
import _ from 'lodash';

class RatingList extends React.Component {
    render() {
        const today = moment().subtract(1, 'week');
        const year = moment().year();
        const isoWeek = today.isoWeek();
        return <div>
            <Typography style={{marginBottom: 60}}>
                <Typography.Title>
                    Рейтинг направлений Атласа
                </Typography.Title>
            </Typography>
            <QueryRenderer 
                type="pg"
                query={globalRating(year, isoWeek)}
                render={({resultSet}) => resultSet ? <div>
                    <RatingTable data={resultSet} filter={d => d.ratingHistory.length >= 4} />
                    <div style={{margin: '60px 0 30px 0'}}>
                        <Typography.Title level={2}>Новые направления</Typography.Title>
                    </div>
                    <RatingTable data={resultSet} filter={d => d.ratingHistory.length < 4} />
                </div> : 'Загрузка...'}
            />
        </div>
    }
}

const RatingTable = ({filter, data: {loadResponse: {data}}}) => {
    const directions = cleanUpKeys(data)
        .slice()
        .filter(i => filter(i))
        .map(d => ({
            ...d,
            ratingHistory: d.ratingHistory.slice(0, 8)
        }))
        .map(d => ({
            ...d,
            rating: Math.floor(_.sumBy(d.ratingHistory, r => r[2]) / d.ratingHistory.length)
        }))
    return <Table
        pagination={false}
        dataSource={_.reverse(_.sortBy(directions, 'rating'))}
        rowKey="directionId"
    >
        <Table.Column 
            title="Средний рейтинг"
            dataIndex="averageRating"
            width="200px"
            sorter={(a, b) => parseInt(a.averageRating) - parseInt(b.averageRating)}
            render={r => <span style={{fontSize: 30}}>{parseInt(r)}</span>}
        />
        <Table.Column 
            dataIndex="onlineShare"
            title="Доля онлайна"
            sorter={(a, b) => parseFloat(a.onlineShare) - parseFloat(b.onlineShare)}
            render={v => <span style={{fontSize: 26}}>{formatNumber(parseFloat(v) * 100, 0)}%</span>}
        />
        <Table.Column 
            key="history"
            title="История"
            width={100}
            render={(_, item) => <div>
                <WeeksLine 
                    data={item.ratingHistory.map(r => ({
                        week: moment({year: r[0]}).isoWeek(r[1]).isoWeekday(1),
                        value: r[2],
                    }))}
                    showValue={false}
                    size="small"
                    formatTooltip={w => <div style={{textAlign: 'left'}}>
                        Рейтинг: <strong>{w.value}</strong> <br />
                        Неделя: <strong>{formatWeekName(w.week)}</strong>
                    </div>}
                />
            </div>}
        />
        <Table.Column
            title="Франчайзи"
            dataIndex="franchiseeName"
            filters={_.uniqBy(directions, 'franchiseeName').map(r => ({
                text: r.franchiseeName,
                value: r.franchiseeName
            }))}
            onFilter={(v, r) => v === r.franchiseeName}
        />
        <Table.Column 
            title="Направление"
            dataIndex="directionId"
            render={(id, r) => {
                if (r.isAvailable) {
                    return <Link to={`/direction/${id}/${r.year}/${r.isoWeek}`}>{r.directionName}</Link>
                } else {
                    return <span>{r.directionName}</span>
                }
            }}
        />
    </Table>
}

export default RatingList;
