import React from 'react';
import { PageHeader, Typography, Row, Col, Statistic } from 'antd';
import block from 'bem-cn';
import { withRouter } from 'react-router-dom';
import WeeksLine from '../../components/weeksLine';
import RatingDetails from '../../components/graphics/RatingDetails';
import {connect} from 'react-redux'
import {loadDirections, selectDirection} from './reducer'
import {formatWeekName} from '../../utils/format'
import DirectionMenu from './directionMenu';
import Spinner from '../../components/spinner';

import './direction.scss';

class Partner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            directions: []
        };
        const { directionId } = props.match.params;
        const { year, isoWeek } = this.props.match.params;
        props.loadDirections(directionId, year, isoWeek);
    }
    componentDidUpdate(prevProps) {
        const { year, isoWeek } = this.props.match.params;
        const prevYear = prevProps.match.params.year;
        const prevIsoWeek = prevProps.match.params.isoWeek;
        if (year && isoWeek && (year !== prevYear || isoWeek !== prevIsoWeek)) {
            this.props.selectDirection(year, isoWeek, this.props.directions);
        }
    }
    render() {
        const b = block('direction-dash-header');
        const { directionId, year, isoWeek } = this.props.match.params;
        if (this.props.directions.length > 0 && this.props.activeDirection) {
            const direction = this.props.activeDirection;
            return <div>
                <PageHeader
                    title={direction.directionName}
                    subTitle={direction.partnerName}
                    onBack={() => this.props.history.push('/top')}
                    footer={<DirectionMenu 
                        directionId={directionId} 
                        year={year} isoWeek={isoWeek}
                        history={this.props.history} 
                        match={this.props.match} b={b} />}
                >
                    <Row>
                        <Col span={16}>
                            <Typography.Title style={{ marginTop: 12 }} level={3}>
                                Рейтинг за неделю {formatWeekName(direction.startDate)}
                            </Typography.Title>
                            <Row gutter={30} type="flex" justify="start" align="bottom">
                                <Col>
                                    <div className={b('navigator')}>
                                        <WeeksLine 
                                            data={this.props.directions.map(d => ({
                                                value: d.rating,
                                                week: d.startDate,
                                                isoWeek: d.isoWeek,
                                                year: d.year,
                                                isActive: d.isActive
                                            }))}
                                            link={i => [
                                                `/direction/${directionId}/${i.year}/${i.isoWeek}`,
                                                document.location.pathname.split(this.props.match.url)[1]
                                            ].filter(Boolean).join("")}
                                            // colorRangeEnabled={false} 
                                        />
                                    </div>
                                </Col>
                                <Col style={{minWidth: 180}}>
                                    <Statistic
                                        valueStyle={{ fontSize: "4em" }}
                                        value={direction.averageRating}
                                        suffix=" / 100"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <RatingDetails />
                        </Col>
                    </Row>
                </PageHeader>
            </div>
        } else {
            return <Spinner data={false} />
        }
    }
}

const mapDispatchToProps = dispatch => ({
    loadDirections: (directionId, year, isoWeek) => dispatch(loadDirections(directionId, year, isoWeek)),
    selectDirection: (year, isoWeek, directions) => dispatch(selectDirection(year, isoWeek, directions))
})
const mapStateToProps = ({directionReducer}) => directionReducer;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Partner));