import React from 'react';
import {Tag} from 'antd';
import './big-number.scss';
import block from 'bem-cn';
import { formatNumber } from '../../utils/format';

/**
 * 
 * @param {{
 *  value: Number,
 *  title: String?,
 *  subtitle: String?,
 *  prevValue: Number?,
 *  suffix: String?,
 *  fraction: Number?,
 *  reverse: Boolean
 * }} props 
 */
const BigNumber = (props) => {
    const b = block('big-number');
    const {value, prevValue, title, suffix} = props;
    const fraction = props.fraction === undefined  ? 1 : props.fraction;
    const SuffixItem = () => suffix ? <span className="suffix">{suffix}</span> : null;
    var diff = null;
    var color = null;
    if (value !== null && prevValue !== null) {
        if (props.reverse) {
            diff = value > 0 ? 100 * (prevValue / value - 1) : 0;
        } else {
            diff = prevValue > 0 ? 100 * (value / prevValue - 1) : 0;
        }
        if (diff > 0) {
            color = 'green'
        } else if (diff < 0) {
            color = 'red';
        }
    }
    const okArrow = props.reverse ? '↓' : '↑';
    const notOkArrow = props.reverse ? '↑' : '↓';
    return <div className={b()}>
        <div className={b('title')}>
            {diff !== null && <Tag 
                color={color}
            >
                {diff > 0 ? okArrow : diff < 0 ? notOkArrow : ''} {formatNumber(Math.abs(diff), Math.abs(diff) < 1 ? 2 : 0)}%
            </Tag>}
            <div>{title}</div>
        </div>
        {props.subtitle && <div>{props.subtitle}</div>}
        <div className={b('value')}>
            <div>{formatNumber(value || 0, fraction)}<SuffixItem /></div>
        </div>
        {prevValue !== null && <div className={b('prev-value')}>
            Прошлая неделя: {formatNumber(prevValue, fraction)}<SuffixItem />
        </div>}
    </div>
}


export default BigNumber;