import React from 'react';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { Table, Button, Dropdown, Icon, Menu } from 'antd';
import { GraphProps } from './types';
import { format } from '../../../utils/format';
import moment from 'moment';


class ChartTable extends React.Component<GraphProps> {
    defaultColumnParams(k) {
        return {
            sorter: (a, b) => this.filterValues(a[k], b[k])
        }
    }
    filterValues(a, b) {
        if (typeof(a) === "number" && typeof(b) === "number") {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        }
        if (typeof(a) === "string" || typeof(b) === "string") {
            return a.localeCompare(b);
        }
        return 0;
    }
    saveTo(type = 'xlsx') {
        const { data, timeDimensions, measures, dimensions } = this.props;

        if (type === 'json') {
            const blob = new Blob([
                JSON.stringify(data, {}, 2)
            ], {type: "text/plain;charset=utf-8"});
            saveAs(blob, "Отчёт.json");
            return;
        }

        const xlsxData = data.map(item => {
            const result = {};
            const allDimensions = {...timeDimensions, ...measures, ...dimensions};
            Object.keys(allDimensions).forEach(td => {
                if (!allDimensions[td].shortTitle.startsWith('_')) {
                    result[allDimensions[td].shortTitle] = item[td];
                }
            })
            Object.keys(timeDimensions).forEach(td => {
                result[timeDimensions[td].shortTitle] = moment(item[td])
                    .format('DD.MM.YYYY HH:mm')
            })
            return result;
        })

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
            wb, 
            XLSX.utils.json_to_sheet(xlsxData)
        )
        XLSX.writeFile(wb, `Отчёт.${type}`);
    }
    render() {
        const { dimensions, measures, timeDimensions, data, formats } = this.props;
        const columns = {
            ...timeDimensions,
            ...dimensions,
            ...measures
        };
        return <div>
            <div style={{marginBottom: 20}}>
                <Dropdown 
                    overlay={(
                        <Menu>
                            <Menu.Item key="xlsx" onClick={() => this.saveTo('xlsx')}>
                                Excel
                            </Menu.Item>
                            <Menu.Item key="csv" onClick={() => this.saveTo('csv')}>
                                CSV
                            </Menu.Item>
                            <Menu.Item key="json" onClick={() => this.saveTo('json')}>
                                JSON
                            </Menu.Item>
                        </Menu>
                    )}
                >
                    <Button>
                        Скачать <Icon type="down" />
                    </Button>
                </Dropdown>
            </div>
            <Table
                dataSource={data}
                pagination={false}
                rowKey={i => Object.keys(columns).map(c => i[c]).join('.')}
            >
                {Object.keys(columns).filter(c => !columns[c].shortTitle.startsWith('_')).map(c => (
                    <Table.Column 
                        key={c}
                        dataIndex={c}
                        sorter={(a, b) => {
                            if (a[c] > b[c]) {
                                return 1;
                            }
                            if (a[c] < b[c]) {
                                return -1;
                            }
                            return 0;
                        }}
                        render={v => format(v, formats[c])}
                        title={columns[c].shortTitle}
                    />
                ))}
            </Table>
        </div>
    }
}

export default ChartTable;