import React from 'react';
import {Menu} from 'antd';
import {Link, Route} from 'react-router-dom';
import KPI from './kpi';
import Fouls from './fouls';
import {connect} from 'react-redux';

const menus = [
    {
        id: "feedback",
        title: "Отзывы"
    },
    {
        id: "overworking",
        title: "Переработки"
    },
    {
        id: "cancellations",
        title: "Отмены"
    },
    {
        id: "delay",
        title: "Опоздания"
    }
]

const DirectionMenu = ({ b, match, directionId, activeDirection, directions, year, isoWeek }) => {
    const basePath = `/direction/${directionId}/${year}/${isoWeek}`;
    const activeMenu = menus.filter(m => `${basePath}/rating/${m.id}` === window.location.pathname)[0];
    return <div>
        <Menu selectedKeys={[window.location.pathname]} mode="horizontal" defaultSelectedKeys={["1"]}>
            <Menu.Item key={`${basePath}`}>
                <Link to={`${basePath}`}>
                    Ключевые показатели
                </Link>
            </Menu.Item>
            <Menu.SubMenu
                title={<span>
                    {["Детализация рейтинга", activeMenu && activeMenu.title.toLowerCase()].filter(Boolean).join(" — ")}
                </span>}
            >
                {menus.map(m => <Menu.Item key={`${basePath}/rating/${m.id}`}>
                    <Link key={m.id} to={`${basePath}/rating/${m.id}`}>{m.title}</Link>
                </Menu.Item>)}
            </Menu.SubMenu>
        </Menu>
        {activeDirection && <div className={b('body')}>
            {/* <KPI /> */}
            <Route path={`${match.url}`} exact render={() => <KPI basePath={basePath} />} />
            <Route path={`${match.url}/rating`} component={Fouls} />
        </div>}
    </div>
}

const mapStateToProps = ({directionReducer}) => directionReducer;
export default connect(mapStateToProps)(DirectionMenu);