import cubejs from "@cubejs-client/core";
import jwt from 'jsonwebtoken';
import moment from 'moment';
import api from './api';
import firebase from 'firebase/app';

/**
 * @return {Promise<{bq: String, mysql: String}>}
 */
const getToken = () => {
    return new Promise((resolve, reject) => {
        if (localStorage.getItem('cube')) {
            const token = JSON.parse(localStorage.getItem('cube'));
            const bq = jwt.decode(token.bq);
            const mysql = jwt.decode(token.mysql);
            const user = bq.u;
            if (typeof user.serviceId === 'object') { // migration serviceId to array
                window.yaCounter54412891 && window.yaCounter54412891.userParams(user);
                if (moment.unix(mysql.exp) > moment() && moment.unix(bq.exp) > moment()) {
                    resolve(token);
                    return
                }
            }
        } 
        if (process.env.REACT_APP_JWT_TOKEN_MYSQL && process.env.REACT_APP_JWT_TOKEN_BQ) {
            resolve({
                bq: process.env.REACT_APP_JWT_TOKEN_BQ,
                mysql: process.env.REACT_APP_JWT_TOKEN_MYSQL,
                pg: process.env.REACT_APP_JWT_TOKEN_PG
            });
            return
        } 
        api.auth()
            .then(claims => {
                localStorage.setItem('cube', JSON.stringify(claims));
                resolve(claims);
            });
    })

}

/**
 * 
 * @param {'pg' | 'bq' | 'mysql'} type 
 */
const cubeJsApi = type => new Promise((resolve, reject) => {
    const API_URL = process.env.REACT_APP_CUBE_API_URL;
    getToken()
        .then(token => {
            resolve(
                cubejs(
                    token[type],
                    {
                        apiUrl: API_URL
                    }
                )
            )
        })
        .catch(e => reject(e))
})

const firebaseConfig = {
    apiKey: "AIzaSyA19AzhDjqgbp_7VCQbqpmPxkegxFTwu8o",
    authDomain: "atlas-cube.firebaseapp.com",
    databaseURL: "https://atlas-cube.firebaseio.com",
    projectId: "atlas-cube",
    storageBucket: "",
    messagingSenderId: "106738985372",
    appId: "1:106738985372:web:7bfb3567304c73f6"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);

export {
    getToken, cubeJsApi,
    firebaseApp
}