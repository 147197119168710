import React from 'react';
import { Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { loadUsers, deleteUser, updateUser } from './reducer'
import { UsersTable } from './usersTable';

class Users extends React.Component {
    constructor(props) {
        super(props);
        props.loadUsers(this.props.userClaims);
    }
    componentDidUpdate(prevProps) {

    }
    render() {
        return <div>
            <Typography style={{marginBottom: 60}}>
                <Typography.Title>
                    Пользователи Атласа
                </Typography.Title>
            </Typography>
            { this.props.users ?
                <div>
                    <UsersTable data={this.props.users}
                                filter={() => true}
                                userClaims={this.props.userClaims}
                                onDelete={this.props.onDelete}
                                onUpdate={this.props.onUpdate}
                    />
                </div>
                : 'Загрузка...'
            }
        </div>
    }
}

const mapDispatchToProps = dispatch => ({
    loadUsers: ( claims ) => dispatch(loadUsers(claims)),
    onDelete: (id) => dispatch(deleteUser(id)),
    onUpdate: (user) => dispatch(updateUser(user)),
})
const mapStateToProps = ({usersReducer: {users},  authReducer: { userClaims }}) => ({ users, userClaims });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));