import React from 'react';
import {cubeJsApi} from '../config';

import { QueryRenderer } from "@cubejs-client/react";

class QR extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cubeJsApi: null
        }
        cubeJsApi(this.props.type)
            .then(cubeJsApi => this.setState({cubeJsApi}))
    }
    render() {
        switch(this.state.cubeJsApi) {
            case null:
                return <div>Загрузка...</div>
            default:
                return <QueryRenderer 
                    query={this.props.query}
                    cubejsApi={this.state.cubeJsApi}
                    render={this.props.render}
                />
        }
    }
}


export default QR;