// @flow
import ColorHash from 'color-hash';

export class Color {
    h: number;
    s: number;
    l: number;
    a: number;

    constructor(color: {h: number, s: number, l: number, a: number}) {
        this.h = color.h;
        this.s = color.s;
        this.l = color.l;
        this.a = color.a || 1;
    }

    toString(active: boolean = true) {
        const alpha = this.a * active ? 1 : 0.5;
        return `hsla(${this.h}, ${this.s}%, ${this.l}%, ${alpha})`;
    }

    static randomColor(text: string): Color {
        const [h, s, l] = (new ColorHash()).hsl(text);
        return new Color({
            h, 
            s: s * 100, 
            l: (l > 0.4 ? 0.4 : l) * 100
        });
    }
}