import React from 'react';
import { Icon } from 'antd';


const StarIcon = ({rating, style}) => {
    return (
        <Icon 
            type="star"
            theme="twoTone"
            twoToneColor={`hsla(${
                parseInt(rating) * 15 - 10
                }, 80%, 50%, 1)`}
            style={style}
        />
    )
}
export default StarIcon;