// @flow
import React from 'react';
import { Checkbox } from 'antd';
import DashSelect, {DashSelectType} from './DashSelect';

const groups = {
    pax: 'Пассажиропоток',
    cancellations: 'Отмены и переносы',
    sales: 'Продажи',
    revenue: 'Деньги',
    logistic: 'Логистика',
    retention: 'Возвращаемость',
    quality: 'Качество',
    economy: '🏦 Экономика'
}

const metrics = [
    {
        value: 'totalRevenue',
        title: '💰 Выручка',
        group: groups.revenue
    },
    {
        value: 'avgTicketPrice',
        title: '💲 Средняя стоимость билета',
        group: groups.revenue
    },
    {
        value: 'revenuePerTrip',
        title: '💸 Средняя выручка на рейс',
        group: groups.revenue
    },
    {
        value: 'refundRevenue',
        title: 'Комиссия за возврат',
        group: groups.revenue
    },
    {
        value: 'discount',
        title: '🎁 Размер скидки',
        group: groups.revenue
    },
    {
        value: 'pax',
        title: '👨‍🦱 Количество пассажиров',
        group: groups.pax
    },
    {
        value: 'newUsersShare',
        title: '％ Доля новых пассажиров',
        group: groups.pax
    },
    {
        value: 'lateCancellationsCount',
        title: '🌚 Количество поздних отмен',
        group: groups.cancellations
    },
    {
        value: 'lateCancellationsShare',
        title: '％ Доля поздних отмен',
        group: groups.cancellations
    },
    {
        value: 'cancellationsCount',
        title: '❌ Количество отмен',
        group: groups.cancellations
    },
    {
        value: 'cancellationsShare',
        title: '％ Доля отмен',
        group: groups.cancellations
    },
    {
        value: 'salesCount',
        title: '🎟 Количество продаж',
        group: groups.sales
    },
    {
        value: 'salesRevenue',
        title: '💰 Выручка с продаж (без учёта отмен)',
        group: groups.sales
    },
    {
        value: 'Trips.count',
        title: '🚌 Количество рейсов',
        group: groups.logistic
    },
    {
        value: 'paxPerTrip',
        title: 'В среднем пассажиров на рейс',
        group: groups.logistic
    },
    {
        value: 'Trips.seats',
        title: 'Количество пассажиромест',
        group: groups.logistic
    },
    {
        value: 'fillShare',
        title: 'Заполняемость',
        group: groups.logistic
    },
    {
        value: 'returnedInWeekShare',
        title: '% вернувшихся в течении недели',
        group: groups.retention
    },
    {
        value: 'returnedIn3WeeksShare',
        title: '% вернувшихся в течении 3 недель',
        group: groups.retention
    },
    {
        value: 'returnedInWeekCount',
        title: 'Вернулись в течении недели',
        group: groups.retention
    },
    {
        value: 'returnedIn3WeeksCount',
        title: 'Вернулись в течении 3 недель',
        group: groups.retention
    },
    {
        value: 'Passengers.avgDayElapsedBeforeNextTrip',
        title: 'Дней до следующей поездки',
        group: groups.retention
    },
    {
        value: 'Feedback.rating',
        title: '⭐️ Средний рейтинг',
        group: groups.quality
    },
    {
        value: 'Feedback.count',
        title: '⭐️ Количество отзывов',
        group: groups.quality
    },
    {
        value: 'PassengersChanges.cancelled_by_operator',
        title: 'Отмены перевозчиком',
        group: groups.cancellations
    },
    {
        value: 'PassengersChanges.moved_by_operator',
        title: 'Переносы',
        group: groups.cancellations
    },
    {
        value: 'Passengers.operatorCancellationsShare',
        title: 'Доля отмен перевозчиком',
        group: groups.cancellations
    },
    {
        value: 'Passengers.operatorMoveShare',
        title: 'Доля переносов',
        group: groups.cancellations,
    },
    {
        value: 'Trips.distance',
        title: 'Пройденное расстояние',
        group: groups.economy,
    },
    {
        value: 'Trips.priceKmRub',
        title: 'Себестоимость км. (средняя)',
        group: groups.economy,
    },
    {
        value: 'Trips.tripPriceRub',
        title: 'Себестоимость рейса',
        group: groups.economy,
    },
    {
        value: 'Trips.tripDuration',
        title: 'Длительность рейсов',
        group: groups.economy,
    },
]

type MetricsType = DashSelectType & {
    showSharesChanged: (value: boolean) => void,
    showShares: boolean
};

const Metrics = (props: MetricsType) => {
    return <DashSelect 
        {...props}
        items={metrics}
        placeholder="Выберите метрику"
        max={4}
    >
        <Checkbox 
            onChange={({ target: { checked } }) => props.showSharesChanged(checked)}
            checked={props.showShares}
        >
            Отобразить в процентах
        </Checkbox>
    </DashSelect>
}

export default Metrics;