// @flow
import React from 'react';
import DashSelect, {DashSelectType} from './DashSelect';

export const groupings = [
    {
        value: 'date',
        title: '📆 По дате',
        exclude: ['trips', 'weekDay']
    },
    {
        value: 'promocode',
        dimensions: ['Passengers.promocode'],
        title: '🎁 По промокоду',
    },
    {
        value: 'driver',
        dimensions: ['Trips.driverId', 'Trips.driverName', 'Trips.driverPhone'],
        title: '👨‍✈️ По водителям'
    },
    {
        value: 'bus',
        dimensions: ['Trips.carId', 'Trips.carName'],
        title: '🚌 По автобусам'
    },
    {
        value: 'depStation',
        dimensions: ['Passengers.depStationId', 'Passengers.depStationName'],
        title: '🚏 По остановке отправления'
    },
    {
        value: 'fromCityName',
        dimensions: ['Passengers.fromCityName'],
        title: '🏙 По городу отправления'
    },
    {
        value: 'depHour',
        dimensions: ['Passengers.depHour'],
        title: '🕐 Час отправления'
    },
    {
        value: 'source',
        dimensions: ['source'],
        title: '📊 По источнику продажи'
    },
    {
        value: 'paymentSource',
        dimensions: ['paymentSource'],
        title: '💳 По способу оплаты'
    },
    {
        value: 'direction',
        dimensions: ['Trips.directionId', 'Trips.directionName'],
        title: '↔️ По направлению'
    },
    {
        value: 'line',
        dimensions: ['Trips.lineId', 'Trips.lineName'],
        title: '↔️ По маршруту'
    },
    {
        value: 'leg',
        dimensions: ['Passengers.legId', 'Passengers.legName'],
        title: '↔️ По плечам'
    },
    {
        value: 'service',
        dimensions: ['Trips.serviceId', 'Trips.serviceName'],
        title: 'По перевозчику'
    },
    {
        value: 'partner',
        dimensions: ['Trips.partnerId', 'Trips.partnerName'],
        title: '👨‍💼 По партнёру'
    },
    {
        value: 'currency',
        dimensions: ['Trips.currency'],
        title: '💵 По валюте'
    },
    {
        value: 'trips',
        title: '➿ По рейсам',
        dimensions: ['Trips.tripId', 'Trips.localDepDate'],
        exclude: ['date']
    },
    {
        value: 'weekDay',
        title: 'По дням недели',
        dimensions: ['Trips.weekDay'],
        exclude: ['date']
    },
    {
        value: 'isNewPass',
        title: 'Новые / вернувшиеся пассажиры',
        dimensions: ['isNewPass']
    }
]

const Groupings = (props: DashSelectType) => {
    return <DashSelect 
        items={groupings} {...props} 
        placeholder="Выберите группировку"
        max={5}
    />
}

export default Groupings;