import {loadDirectionData, loadFeedbackData} from './loadData';
import moment from 'moment';

export const DIRECTIONS_LOAD_START = 'DIRECTIONS_LOAD_START';
export const DIRECTIONS_LOADED = 'DIRECTIONS_LOADED';
export const ACTIVE_DIRECTION_SELECTED = 'ACTIVE_DIRECTION_SELECTED';
export const ACTIVE_DIRECTION_NOT_FOUND = 'ACTIVE_DIRECTION_NOT_FOUND';
export const FEEDBACK_LOADED = 'FEEDBACK_LOADED';
export const FEEDBACK_LOAD_START = 'FEEDBACK_LOAD_START';

const defaultState = {
    directions: [],
    activeDirection: null,
    directionId: null,
    feedback: {
        loaded: false,
        items: []
    }
}

export const directionReducer = (state = defaultState, action) => {
    const {payload} = action;
    switch(action.type) {
        case DIRECTIONS_LOAD_START:
            return defaultState
        case DIRECTIONS_LOADED:
            return {
                ...state,
                directions: payload,
                directionId: action.directionId
            }
        case ACTIVE_DIRECTION_SELECTED:
            const {activeDirection, previousDirection} = payload;
            return {
                ...state,
                activeDirection, previousDirection,
                directions: state.directions.map(d => ({
                    ...d,
                    isActive: d.isoWeek === activeDirection.isoWeek
                        && d.year === activeDirection.year
                }))
            }
        case FEEDBACK_LOADED:
            return {
                ...state,
                feedback: {
                    loaded: true,
                    items: payload
                }
            }
        case FEEDBACK_LOAD_START:
            return {
                ...state,
                feedback: defaultState.feedback
            }
        default:
            return state
    }
}

/**
 * 
 * @param {Number} year 
 * @param {Number} isoWeek 
 * @param {Array.<any>} directions 
 */
const findDirection = (year, isoWeek, directions) => {
    const candidates = directions.filter(d => {
        return d.isoWeek === parseInt(isoWeek)
        && d.year === parseInt(year)
    });
    if (candidates.length > 0) {
        return candidates[0];
    } else {
        return null;
    }
}

export const selectDirection = (year, isoWeek, directions) => {
    return dispatch => {
        const previousWeek = moment({
            year: year
        });
        previousWeek.isoWeek(isoWeek);
        previousWeek.subtract(8, 'days');
        const activeDirection = findDirection(year, isoWeek, directions);
        if (activeDirection) {
            const previousDirection = findDirection(previousWeek.year(), previousWeek.isoWeek(), directions);
            dispatch({
                type: ACTIVE_DIRECTION_SELECTED,
                payload: {
                    activeDirection,
                    previousDirection
                }
            })
        } else {
            dispatch({type: ACTIVE_DIRECTION_NOT_FOUND})
        }
    }
}

export const loadDirections = (directionId, year, isoWeek) => {
    return dispatch => {
        dispatch({
            type: DIRECTIONS_LOAD_START
        })
        loadDirectionData(directionId)
            .then(d => {
                dispatch({
                    type: DIRECTIONS_LOADED,
                    payload: d,
                    directionId
                });
                dispatch(selectDirection(year, isoWeek, d));
            })
    }
}

export const loadFeedback = (directionId, year, isoWeek, deep) => {
    return dispatch => {
        dispatch({type: FEEDBACK_LOAD_START});
        loadFeedbackData(directionId, year, isoWeek, deep)
            .then(d => dispatch({
                type: FEEDBACK_LOADED,
                payload: d
            }))
    }
}