import React from 'react';
import { Dashboard, DashboardItem } from '../../components/dashboard';
import BigNumber from '../../components/bigNumber';
import LineChart from '../../components/graphics/Line';
import { Divider, Table } from 'antd';
import { formatWeekName } from '../../utils/format'
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

const KPI = ({ basePath, directions, activeDirection, previousDirection }) => {
    return <Dashboard>
        {/* <Typography.Title level={3}>Ключевые метрики</Typography.Title> */}
        <DashboardItem
            bordered={false}
            span={12}
        >
            <BigNumber
                suffix=' руб.'
                value={activeDirection.gmv}
                prevValue={previousDirection && previousDirection.gmv} title="Выручка"
            />
        </DashboardItem>
        <DashboardItem
            bordered={false}
            span={12}
        >
            <LineChart
                height={180}
                barsTitle="Выручка"
                minValue={0}
                hideYAxis={true}
                data={directions.map(d => ({
                    d: d.endDate.format('DD.MM'),
                    v: d.gmv,
                    dateText: formatWeekName(d.startDate),
                    isActive: d.isActive
                }))}
            />
        </DashboardItem>
        <Divider />
        <DashboardItem bordered={false} span={8}>
            <BigNumber suffix=" %"
                value={activeDirection.onlinePaymentShare * 100}
                prevValue={previousDirection && previousDirection.onlinePaymentShare * 100}
                title="Онлайн продажи"
            />
        </DashboardItem>
        <DashboardItem bordered={false} span={8}>
            <BigNumber
                suffix=" %"
                fraction={1}
                reverse={true}
                value={100 * activeDirection.cancelledPassengersShare}
                prevValue={previousDirection && 100 * previousDirection.cancelledPassengersShare}
                // value={10}
                // prevValue={20}
                title="Отмены" />
        </DashboardItem>
        <DashboardItem bordered={false} span={8}>
            <BigNumber suffix=" / 5"
                value={activeDirection.feedbackRating}
                prevValue={previousDirection && previousDirection.feedbackRating}
                fraction={1}
                title="Отзывы" />
        </DashboardItem>
        <Divider />
        <DashboardItem bordered={false} span={24}>
            <div style={{fontSize: 20, marginBottom: '2em'}}>Нарушения и штрафные баллы</div>
            <Table
                // bodyStyle={{fontSize: '1.1em'}}
                // size="middle"
                pagination={false}
                rowKey="link"
                style={{fontSize: 18}}
                dataSource={[
                    {foul: 'Низкие отзывы', penalty: activeDirection.feedbackFoul, link: "feedback"},
                    {foul: 'Отмены пассажиров', penalty: activeDirection.cancellationsFoul, link: "cancellations"},
                    {foul: 'Переработки водителей', penalty: activeDirection.overworkingFoul, link: "overworking"},
                    {foul: 'Опоздания', penalty: activeDirection.delayFoul, link: "delay"},
                ]}
            >
                <Table.Column dataIndex="foul" title="Нарушение" render={(_, r) => <Link to={`${basePath}/rating/${r.link}`}>{r.foul}</Link>} />
                <Table.Column title="Штрафные баллы" dataIndex="penalty" render={v => <strong>{v || 0}</strong>} />
            </Table>
        </DashboardItem>
    </Dashboard>
}

const mapStateToProps = ({ directionReducer }) => directionReducer;

export default connect(mapStateToProps)(KPI);