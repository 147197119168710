import React from 'react';
import {connect} from 'react-redux';
import {Dashboard, DashboardItem} from '../../../../components/dashboard';
import BigNumber from '../../../../components/bigNumber';
import _ from 'lodash';
import { Table } from 'antd';
import { formatNumber } from '../../../../utils/format';
import moment from 'moment';

const Delay = ({activeDirection, previousDirection}) => {
    const configRanges = Object.keys(activeDirection.delayExtra.config.ranges)
        .sort((a, b) => parseInt(a) - parseInt(b));
    const ranges = configRanges.map((r, i) => {
        const trips = activeDirection.delayExtra.ranges[r]
        const foul = activeDirection.delayExtra.config.ranges[r];
        return {
            trips,
            foul,
            range: r,
            nextRange: configRanges[i + 1]
        }
    }).map(r => ({
        ...r,
        title: [
            `от ${r.range}`,
            r.nextRange && `до ${r.nextRange}`,
            'минут'
        ].filter(Boolean).join(" ")
    }))
    const delayShare = direction => {
        return Math.ceil(
            100 * _.sum(_.values(direction.delayExtra.ranges)) / direction.delayExtra.total_trips
        );
    }
    return <Dashboard>
        <DashboardItem span={12}>
            <BigNumber 
                reverse
                title="Штрафные баллы"
                value={activeDirection.delayFoul}
                prevValue={previousDirection && previousDirection.delayFoul}
            />
        </DashboardItem>
        <DashboardItem span={12}>
            <BigNumber 
                reverse
                title="% опоздавших рейсов"
                value={delayShare(activeDirection)}
                prevValue={previousDirection && delayShare(previousDirection)}
                suffix=" %"
            />
        </DashboardItem>
        {/* <Divider /> */}
        <DashboardItem span={24} title="Детализация опозданий">
            <Table
                pagination={false}
                dataSource={ranges}
                rowKey="range"
            >
                <Table.Column 
                    title="Опоздание"
                    dataIndex="title"
                    render={v => <strong>{v}</strong>}
                />
                <Table.Column 
                    title="Количество рейсов"
                    dataIndex="trips"
                    render={v => <div>
                        <strong>{v}</strong>
                        {parseInt(v) > 0 && <span> — <i>{formatNumber(
                            100 * parseInt(v) / activeDirection.delayExtra.total_trips,
                            2
                        )} %</i></span>}
                    </div>}
                />
                <Table.Column 
                    title="Штрафной балл"
                    key="foul"
                    render={(_, r) => {
                        const total = activeDirection.delayExtra.total_trips;
                        return <span>
                            <span style={{fontSize: 28}}>{formatNumber(r.foul * r.trips / total, 2)}</span>
                            <span style={{color: '#aaa'}}>
                                {" "}= {formatNumber(100 * r.trips / total, 2)}% x {r.foul}
                            </span>
                        </span>
                    }}
                />
            </Table>
        </DashboardItem>
        <DashboardItem span={24} title="Рейсы с опоздениями">
            <Table
                dataSource={activeDirection.delayExtra.trips}
                rowKey="trip_id"
            >
                <Table.Column 
                    title="Маршрут"
                    dataIndex="line_name"
                />
                <Table.Column
                    title="Водитель"
                    filters={_.uniqBy(activeDirection.delayExtra.trips, 'driver_id').map(t => ({
                        text: t.driver_name,
                        value: t.driver_id
                    }))}
                    onFilter={(v, r) => v === r.driver_id}
                    dataIndex="driver_name"
                />
                <Table.Column 
                    title="Дата отправления"
                    dataIndex="local_datetime"
                    render={v => moment.utc(v).utc().format('D MMM в HH:mm')}
                />
                <Table.Column 
                    title="Опоздание"
                    dataIndex="delay"
                    sorter={(a, b) => a.delay - b.delay}
                    render={v => `${v} мин.`}
                />
            </Table>
        </DashboardItem>
    </Dashboard>
}

const mapStateToProps = ({directionReducer}) => directionReducer;

export default connect(mapStateToProps)(Delay);