import {FilterType} from '../../../types/cube';
import Cube from '../../../utils/cube';

export type FilterItemType = FilterType & {
    title: String;
    placeholder: String;
    type: 'list' | 'number';
    loadItems: () => Promise<Array<{value: String, title: String}>>;
    max?: Number;
    min?: Number;
    units?: String;
    group?: String;
}

const filterGroups = {
    common: 'Основные',
    retention: 'Возвращаемость',
    logistic: 'Логистика',
    sales: 'Продажи'
}

const filtersTypes: Array<FilterItemType>  = [
    {
        dimension: 'Trips.serviceId',
        operator: 'equals',
        values: [],
        title: 'По перевозчику',
        placeholder: 'Любой перевозчик',
        type: 'list',
        group: filterGroups.common,
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Trips', 'partnerId', 'serviceId', 'serviceName');
            return data.map(d => ({
                value: d.serviceId, title: d.serviceName,
                partnerId: d.partnerId,
            }));
        }
    },
    {
        dimension: 'Trips.partnerId',
        operator: 'equals',
        values: [],
        title: 'По партнёру',
        group: filterGroups.common,
        placeholder: 'Любой партнёр',
        type: 'list',
        loadItems: async() => {
            const data = await Cube.getDimensions('bq', 'Trips', 'partnerId', 'partnerName');
            return data.map(d => ({
                value: d.partnerId, title: d.partnerName
            }));
        }
    },
    {
        dimension: 'Trips.directionId',
        operator: 'equals',
        values: [],
        title: 'По направлению',
        placeholder: 'Любое направление',
        group: filterGroups.common,
        type: 'list',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Trips', 'partnerId', 'serviceId', 'directionId', 'directionName');
            return data.map(d => ({
                value: d.directionId, title: d.directionName, 
                serviceId: d.serviceId,
                partnerId: d.partnerId,
            }));
        }
    },
    {
        dimension: 'Trips.lineId',
        operator: 'equals',
        values: [],
        title: 'По маршруту',
        placeholder: 'Любой маршрут',
        group: filterGroups.common,
        type: 'list',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Trips', 'partnerId', 'serviceId', 'directionId', 'lineId', 'lineName');
            return data.map(d => ({
                value: d.lineId, 
                title: d.lineName, 
                directionId: d.directionId, 
                serviceId: d.serviceId,
                partnerId: d.partnerId,
            }));
        }
    },
    {
        dimension: 'Trips.carId',
        operator: 'equals',
        values: [],
        title: 'По машине',
        placeholder: 'Все машины',
        group: filterGroups.common,
        type: 'list',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Trips', 'carId', 'carName', 'carIsActive');
            return data.map(d => ({value: d.carId, title: d.carName}));
        }
    },
    {
        dimension: 'Trips.driverId',
        operator: 'equals',
        values: [],
        title: 'По водителю',
        placeholder: 'Все водители',
        group: filterGroups.common,
        type: 'list',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Trips', 'partnerId', 'serviceId', 'driverId', 'driverName');
            return data.map(d => ({
                value: d.driverId, title: d.driverName, serviceId: d.serviceId,
                partnerId: d.partnerId,
            }));
        }
    },
    {
        dimension: 'Passengers.source',
        operator: 'equals',
        values: [],
        title: 'По источнику бронирования',
        placeholder: 'Любой источник',
        group: filterGroups.sales,
        type: 'list',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'source');
            return data.map(d => ({value: d.source, title: d.source}));
        }
    },
    {
        dimension: 'Passengers.paymentSource',
        operator: 'equals',
        values: [],
        title: 'По способу оплаты',
        placeholder: 'Любой способ оплаты',
        group: filterGroups.sales,
        type: 'list',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'paymentSource');
            return data.map(d => ({value: d.paymentSource, title: d.paymentSource}));
        }
    },
    {
        dimension: 'Passengers.isNewPass',
        operator: 'equals',
        values: [],
        title: 'Новый / вернувшийся пассажир',
        placeholder: 'Любой',
        group: filterGroups.retention,
        type: 'list',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'isNewPass');
            return data.map(d => ({value: d.isNewPass, title: d.isNewPass}));
        }
    },
    {
        dimension: 'Passengers.legId',
        operator: 'equals',
        values: [],
        title: 'По плечу маршрута',
        placeholder: 'Все плечи',
        group: filterGroups.logistic,
        type: 'list',
        loadItems: async() => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'legId', 'legName');
            return data.map(d => ({
                value: d.legId, title: d.legName
            }));
        }
    },
    {
        dimension: 'Passengers.depHour',
        operator: 'equals',
        values: [],
        title: 'По часу отправления',
        placeholder: 'Все часы',
        group: filterGroups.logistic,
        type: 'list',
        loadItems: async() => {
            const hours = [];
            for (let i = 0; i < 24; i++) {
                hours.push([
                    `0${i}:00`.slice("-5"),
                    `0${i + 1 === 24 ? 0 : i + 1}:00`.slice("-5")
                ].join(" - "))
            }
            return hours.map(h => ({
                value: h, title: h,
            }));
        }
    },
    {
        dimension: 'Passengers.depStationId',
        operator: 'equals',
        values: [],
        title: 'По остановке отправления',
        group: filterGroups.logistic,
        placeholder: 'Все остановки',
        type: 'list',
        loadItems: async() => {
            const data = await Cube.getDimensions('bq', 'Passengers', 
                'serviceId', 'directionId', 'lineId', 'depStationId', 'depStationName');
            return data.map(d => ({value: d.depStationId, title: d.depStationName}));
        }
    },
    {
        dimension: 'Passengers.fromCityName',
        operator: 'equals',
        values: [],
        title: 'По городу отправления',
        placeholder: 'Все города',
        group: filterGroups.logistic,
        type: 'list',
        loadItems: async() => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'fromCityName');
            return data.map(d => ({value: d.fromCityName, title: d.fromCityName}));
        }
    },
    {
        dimension: 'Passengers.toCityName',
        operator: 'equals',
        values: [],
        title: 'По городу назначения',
        placeholder: 'Все города',
        group: filterGroups.logistic,
        type: 'list',
        loadItems: async() => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'toCityName');
            return data.map(d => ({value: d.toCityName, title: d.toCityName}));
        }
    },
    {
        dimension: 'TripsWithPax.fillShare',
        operator: 'gt',
        values: [50],
        title: 'По заполняемости',
        placeholder: 'Заполняемость',
        group: filterGroups.logistic,
        type: 'number',
        min: 0,
        max: 100,
        units: '%'
    },
    {
        dimension: 'Passengers.price',
        operator: 'gt',
        values: [0],
        title: 'По стоимости билета',
        placeholder: 'Стоимость',
        group: filterGroups.common,
        type: 'number',
        min: 0,
        max: 2000,
        units: 'руб.'
    },
    {
        dimension: 'Passengers.dayElapsedBeforeNextTrip',
        operator: 'gt',
        values: [1],
        title: 'Дней до следующей поездки',
        placeholder: 'Дней до поездки',
        group: filterGroups.retention,
        type: 'number',
        min: 0,
        max: 100,
        units: 'дн.'
    },
    {
        dimension: 'Passengers.promocode',
        operator: 'equals',
        values: [],
        title: 'По промокоду',
        placeholder: 'Промокод',
        group: filterGroups.sales,
        type: 'list',
        loadItems: async() => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'promocode');
            return data.map(d => ({value: d.promocode, title: d.promocode}));
        }
    },
    {
        dimension: 'Passengers.hasPd',
        operator: 'equals',
        values: [],
        title: 'Наличие пасп. данных',
        group: filterGroups.common,
        type: 'list',
        loadItems: async () => [{title: 'С указанием п/д', value: true}, {title: 'Без указания п/д', value: false}]
    },
    {
        dimension: 'Trips.currency',
        operator: 'equals',
        values: [],
        title: 'По валюте',
        placeholder: 'Любая валюта',
        group: filterGroups.common,
        type: 'list',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Trips', 'currency');
            return data.map(d => ({value: d.currency, title: d.currency}));
        }
    }
]

export default filtersTypes;