import React from 'react';
import {
        ResponsiveContainer, 
        RadarChart, Radar,
        PolarGrid, PolarAngleAxis, PolarRadiusAxis,
        Tooltip
} from 'recharts';

/**
 * 
 * @param {{
 *  height: Number,
 *  data: Array.<Object>
 * }} props 
 */
const RatingDetails = props => {
    const {height, data} = props;
    return <ResponsiveContainer width="100%" height={height}>
        <RadarChart width={600} height={500} data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="foul" />
          <PolarRadiusAxis/>
          <Radar isAnimationActive={false} dataKey="penalty" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
          <Tooltip />
        </RadarChart>
    </ResponsiveContainer>
}

export default RatingDetails;