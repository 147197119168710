import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

export type DateRangeType = {
    value: [String, String],
    onChange: (dateRange: [String, String]) => void
};

class DateRange extends React.Component<DateRangeType> {
    onChange(date, dateStrings) {
        this.props.onChange(date.map(d => d.startOf('day').format('YYYY-MM-DD')))
    }
    render() {
        const minDate = moment('2021-01-01 00:00');
        const maxDate = moment().add(1, 'year');
        const yesterday = moment().subtract(1, 'day');
        return <RangePicker
            onChange={this.onChange.bind(this)}
            style={{maxWidth: 250}}
            format="DD.MM.YYYY"
            separator="—"
            value={this.props.value && this.props.value.map(v => moment(v))}
            defaultValue={[moment().subtract(7, 'days'), moment().subtract(1, 'days')]}
            ranges={{
                'Вчера': [yesterday, yesterday],
                'Последние 7 дней': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
                'Последние 30 дней': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
                'Последние 365 дней': [moment().subtract(365, 'days'), moment().subtract(1, 'days')]
            }}
            disabledDate={(d) => {
                if (d == null) {
                    return null;
                }

                return (
                    (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
                    (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day'))
                );
            }}
        />
    }
}

export default DateRange;