// @flow
import React from 'react';
import Line from './Line';
import _ from 'lodash';
import moment from 'moment';
import { Tabs, Icon } from 'antd';
import Table from './Table';
import Bar from './Bar';
import memoize from 'memoize-one';

export type GraphComponentTypes = {
    data: CubeResults
}

const prepareGraphData = (
    {annotation: {dimensions, timeDimensions, measures}, data}, 
    showShares: boolean
) => {
    let filteredTimeDimenstions = timeDimensions;
    if (data[0]) {
        filteredTimeDimenstions = Object.keys(timeDimensions)
            .filter(td => data[0][td])
            .reduce((acc, td) => {
                acc[td] = timeDimensions[td];
                return acc;
            }, {})
    }

    const filteredDimensions = Object.keys(dimensions)
        .filter(d => !dimensions[d].shortTitle.startsWith('_'))
        .reduce((acc, d) => ({...acc, [d]: dimensions[d]}), {});

    const grouped = _.groupBy(data, item => [
        ...Object.keys(
            Object.keys(filteredTimeDimenstions).length > 0
                ? filteredTimeDimenstions
                : dimensions
            ).map(d => item[d])
    ]);
    const formats = {};
    const newDimensions = [];
    const yAxises = Object.keys(measures).length > 1 
        && Object.keys(dimensions).length === 0 ?
            Object.keys(measures).map(m => measures[m].shortTitle).slice(0, 2) :
            undefined;

    const merged = _.mapValues(grouped, v => v.reduce((acc, item) => {
        Object.keys(measures).forEach(m => {
            if (Object.keys(filteredDimensions).length > 0) {
                Object.keys(filteredDimensions).forEach(d => {
                    let key;
                    if (Object.keys(filteredTimeDimenstions).length > 0) {
                        key = [
                            item[d],
                            Object.keys(measures).length > 1 
                                ? measures[m].shortTitle.toLocaleLowerCase() : undefined
                        ].filter(i => i !== undefined && i !== null).join(', ');
                        acc[key] = parseFloat(item[m]) || item[m];
                    } else {
                        key = filteredDimensions[d].shortTitle;
                        acc[key] = item[d];
                        acc[measures[m].shortTitle] = parseFloat(item[m]) || item[m];
                    }
                    formats[key] = measures[m].format;
                    if (!newDimensions.includes(key)) newDimensions.push(key);
                })
            } else {
                const key = measures[m].shortTitle;
                acc[key] = parseFloat(item[m]) || item[m];
                formats[key] = measures[m].format;
                if (!newDimensions.includes(key)) newDimensions.push(key);
            }
        });
        Object.keys(filteredTimeDimenstions).forEach(td => {
            if (item[td]) {
                acc[filteredTimeDimenstions[td].shortTitle] = moment(item[td]).format('DD.MM.YYYY');
            };
        })
        return acc;
    }, {}));

    const resultData = _.values(merged);
    if (showShares) {
        if (Object.keys(filteredTimeDimenstions).length > 0) {
            resultData.forEach(item => {
                const sum = newDimensions.reduce((acc, d) => acc + (item[d] || 0), 0);
                newDimensions.forEach(d => {
                    item[d] = (100 * (item[d] / sum)) || 0;
                })
            });
            newDimensions.forEach(d => {
                formats[d] = 'percent';
            })
        } else {
            _.values(measures).forEach(m => {
                const sum = resultData.reduce((acc, item) => {
                    acc += item[m.shortTitle];
                    return acc;
                }, 0)
                resultData.forEach(item => {
                    item[m.shortTitle] = (100 * item[m.shortTitle] / sum) || 0;
                });
            })
            _.values(measures).forEach(m => {
                formats[m.shortTitle] = 'percent';
            })
        }
    }
    return {
        data: resultData,
        timeDimensions: Object.keys(filteredTimeDimenstions).map(td => filteredTimeDimenstions[td].shortTitle),
        dimensions: newDimensions,
        measures: _.values(measures).map(m => m.shortTitle),
        formats,
        yAxises
    };
}

const prepareTableData = (
    {annotation: {dimensions, timeDimensions, measures}, data}, 
    showShares: boolean,
    groupings: Array<string>,
) => {
    const filteredTimeDimensions = groupings.includes('date') ? timeDimensions : {};

    const filteredDimensions = Object.keys(dimensions)
        .filter(d => !d.startsWith('_'))
        .reduce((acc, d) => ({...acc, [d]: dimensions[d]}), {});
    
    const formats = {
        ..._.mapValues(filteredTimeDimensions, () => 'date'),
        ..._.mapValues(dimensions, () => 'string'),
        ..._.mapValues(measures, m => m.type),
    };

    return {
        dimensions: filteredDimensions,
        measures,
        timeDimensions: filteredTimeDimensions,
        data,
        formats,
    }
}

type GraphPropTypes = {
    data: any,
    showShares: boolean,
    groupings: Array<string>
}

export default class Graph extends React.Component<GraphPropTypes> {
    state: {currentTab: ?String, preparedData: {}} = {
        currentTab: null,
        preparedData: null
    }
    prepareData = memoize(
        (data, showShares: boolean, groupings: Array<string>) => {
            if (data) {
                this.setState({
                    preparedGraphData: prepareGraphData(data, showShares),
                    preparedTableData: prepareTableData(data, showShares, groupings)
                })
            }
        }
    )
    componentDidUpdate() {
        this.prepareData(this.props.data, this.props.showShares, this.props.groupings);   
    }
    componentDidMount() {
        const url = new URL(document.location.href);
        const graphType = url.searchParams.get('graphType');
        this.setState({currentTab: graphType ? JSON.parse(graphType) : 'line'})
        this.prepareData(this.props.data);
    }
    invalidForGraph(preparedData): String | false {
        if (!preparedData) {
            return 'Нет данных';
        }
        if (preparedData.timeDimensions.length === 0) {
            return 'Добавбьте группировку по дате'
        }
        if (preparedData.dimensions.length > 20
        ) {
            return `Слишком много получилось линий (${preparedData.dimensions.length} штук)`
        }
        return false;
    }
    invalidForBar(preparedData) {
        if (!preparedData) {
            return 'Нет данных';
        }
        if (preparedData.timeDimensions.length > 0) {
            return 'Не должно быть группировок по дате'
        }
        return false;
    }
    changeTab(currentTab) {
        this.setState({currentTab});
        const url = new URL(document.location.href);
        url.searchParams.set('graphType', JSON.stringify(currentTab));
        window.history.pushState(null, null, url.href);
    } 
    render() {
        const { preparedGraphData, preparedTableData } = this.state;
        return <div>
            {preparedGraphData && <Tabs 
                onChange={this.changeTab.bind(this)}
                tabBarStyle={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
                activeKey={this.state.currentTab}
            >
                <Tabs.TabPane
                    disabled={this.invalidForGraph(preparedGraphData) !== false}
                    tab={<span><Icon type="line-chart" />График</span>}
                    key="line"
                >
                    {this.invalidForGraph(preparedGraphData) === false ? 
                        <Line 
                            {...preparedGraphData}
                            showShares={this.props.showShares}
                            type="line"
                        />
                        : <div>{this.invalidForGraph(preparedGraphData)}</div>
                    }
                </Tabs.TabPane>
                <Tabs.TabPane 
                    disabled={this.invalidForGraph(preparedGraphData) !== false}
                    tab={<span><Icon type="area-chart" />Области</span>}
                    key="area"
                >
                    {this.invalidForGraph(preparedGraphData) === false ? 
                        <Line 
                            {...preparedGraphData}
                            showShares={this.props.showShares}
                            type="area"
                        />
                        : <div>{this.invalidForGraph(preparedGraphData)}</div>
                    }
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<span><Icon type="bar-chart"/>Столбцы</span>}
                    disabled={this.invalidForBar(preparedGraphData, this.props.groupings) !== false}
                    key="bar"
                >
                     {this.invalidForBar(preparedGraphData, this.props.groupings) === false ?
                        <Bar 
                            {...preparedGraphData}
                        />
                        : <div>{this.invalidForBar(preparedGraphData, this.props.groupings)}</div>
                    }
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<span><Icon type="table"/>Таблица</span>}
                    key="table"
                    disabled={false}
                >
                    <Table 
                        {...preparedTableData}
                    />
                </Tabs.TabPane>
            </Tabs>}
        </div>
    }
}