import React from 'react';
import {Spin} from 'antd';

const Spinner = ({data, children, height, tip}) => <React.Fragment>
    {!data && <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: height || 200,
        justifyContent: 'center'
    }}>
        <Spin spinning={!data} tip={tip} />
    </div>}
    {data && children}
</React.Fragment>

export default Spinner;