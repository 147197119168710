export const matchPhrases = (inputPhrase: String, searchPhrase: String) => {
    const searchKeys: Array<String> = searchPhrase ? 
        searchPhrase.toLocaleLowerCase().split(/[^0-9A-zА-я]/).filter(Boolean) :
        [];
    const inputKeys: Array<String> = inputPhrase ?
        inputPhrase.toLocaleLowerCase().split(/[^0-9A-zА-я]/).filter(Boolean)
        : [];
    const keysRes = inputKeys.map(ik => {
        return searchKeys.reduce((acc, sk) => {
            return acc || sk.indexOf(ik) > -1;
        }, false)
    })
    return keysRes.reduce((acc, v) => acc && v, true);
}