/**
 * 
 * @param {Number} directionId
 */
const directionRating = (directionId) => ({
    measures: [
        "DirectionRating.rating",
        "DirectionRating.averageRating",
        "DirectionRating.feedbackFoul",
        "DirectionRating.cancellationsFoul",
        "DirectionRating.overworkingFoul",
        "DirectionRating.gmv",
        "DirectionRating.passengers",
        "DirectionRating.cancelledPassengers",
        "DirectionRating.feedbackRating",
        "DirectionRating.delayFoul",
        "DirectionRating.onlinePaymentRevenue",
        "DirectionRating.wwwRevenue",
        "DirectionRating.onlinePaymentShare",
        "DirectionRating.cancelledPassengersShare"
    ],
    timeDimensions: [],
    dimensions: [
        "DirectionRating.year",
        "DirectionRating.isoWeek",
        "DirectionRating.directionName",
        "DirectionRating.partnerName",
        "DirectionRating.feedbackExtra",
        "DirectionRating.overworkingExtras",
        "DirectionRating.cancellationsExtras",
        "DirectionRating.delayExtra"
    ],
    filters: [
        {
            dimension: "DirectionRating.directionId",
            operator: "equals",
            values: [directionId]
        }
    ]
})

export default directionRating;