import React from 'react';
import {connect} from 'react-redux';
import {Dashboard, DashboardItem} from '../../../../components/dashboard';
import BigNumber from '../../../../components/bigNumber';
import sumBy from 'lodash/sumBy';
import values from 'lodash/values';
import ShareTable from './shareTable';
import OverworkedTripsTable from './overworkedTripsTable';


class Overworking extends React.Component {
    totalHours(direction) {
        return direction ? 
            100 * sumBy(values(direction.overworkingExtras.overworked), 'minutes') 
                /  direction.overworkingExtras.total_duration
            : 0;
    }
    totalFoul(direction) {
        return direction ?
            sumBy(values(direction.overworkingExtras.overworked), 'foul')
            : 0;
    }
    render() {
        const {activeDirection, previousDirection} = this.props;
        return <Dashboard>
            {/* <DashboardItem span={24}>
                <Typography>
                    <Typography.Paragraph>
                        Для каждого рейса мы считаем, сколько суммарно к моменту окончания рейса
                        водитель провёл времени в пути за последние сутки.
                    </Typography.Paragraph>
                </Typography>
            </DashboardItem> */}
            {/* <Divider /> */}
            <DashboardItem span={12}>
                <BigNumber
                    reverse={true}
                    title="Переработки свыше 10 часов"
                    fraction={0}
                    value={this.totalHours(activeDirection)}
                    prevValue={this.totalHours(previousDirection)}
                    suffix=" %"
                />
            </DashboardItem>
            <DashboardItem span={12} bordered={false}>
                <BigNumber
                    reverse={true}
                    title="Штрафные баллы"
                    fraction={0}
                    value={this.totalFoul(activeDirection)}
                    prevValue={this.totalFoul(previousDirection)}
                    // suffix=" %"
                />
            </DashboardItem>
            <DashboardItem span={24} title="Детализация штрафных баллов">
                <ShareTable activeDirection={activeDirection} />
            </DashboardItem>
            <DashboardItem span={24} title="Рейсы с переработками">
                <OverworkedTripsTable activeDirection={activeDirection}  />
            </DashboardItem>
        </Dashboard>
    }
}

const mapStateToProps = ({directionReducer}) => directionReducer;

export default connect(mapStateToProps)(Overworking);