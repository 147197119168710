import React, { useCallback, useState } from 'react';
import { Table, Button, Icon, Popconfirm } from 'antd';
import { UserEdit } from './userEdit';
import { UserType } from './types';
import { FilterType } from '../../types/cube';

const initialFilters = [
    {
        fieldName: 'franchiseeId',
        dimension: 'Trips.partnerId',
        operator: 'equals',
        values: []
    },
    {
        fieldName: 'serviceId',
        dimension: 'Trips.serviceId',
        operator: 'equals',
        values: []
    }
];
export const UsersTable = ({data, userClaims, onDelete, onUpdate}) => {
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [visible, setVisible] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const [filters, setFilters] = useState(initialFilters)
    const onConfirm =  useCallback(
        () => {
            onDelete(deleteUserId);
            setDeleteUserId(null);
        },
        [deleteUserId, setDeleteUserId, onDelete]
    );
    const onCancel =  useCallback(
        () => {
            setDeleteUserId(null);
        },
        [setDeleteUserId]
    );
    const onEditOk =  useCallback(
        () => {
            let claims = {};

            if (selectedUser.claims.admin) {
                claims  = { admin : true }

                if (userClaims.franchiseeId) {
                    claims["franchiseeId"] = userClaims.franchiseeId;
                }
                else {
                    if (filters[0].values && filters[0].values.length > 0) {
                        claims["franchiseeId"] = filters[0].values[0];
                    }
                }
            }
            else {
                claims  = { admin : false }

                if (filters[0].values && filters[0].values.length > 0) {
                    claims["franchiseeId"] = filters[0].values[0];
                }

                if (filters[1].values && filters[1].values.length > 0) {
                    claims["serviceId"] = filters[1].values;
                } else {
                    claims["serviceId"] = [];
                }

                if (userClaims.franchiseeId) {
                    claims["franchiseeId"] = userClaims.franchiseeId;
                }
            }

            onUpdate({
                ...selectedUser,
                claims: claims
            });

            setVisible(false);
            setSelectedUser(null);
        },
        [setVisible, selectedUser, onUpdate, filters, userClaims.franchiseeId]
    );
    const onEditCancel =  useCallback(
        () => {
            setVisible(false);
            setSelectedUser(null);
        },
        [setVisible, setSelectedUser]
    );
    const onRowClick =  useCallback(
        (user: UserType) => {
            setVisible(true);
            const newFilters = filters
                .map(f => {
                    const newValues = user && user.claims && user.claims[f.fieldName]
                        ? (
                            Array.isArray(user.claims[f.fieldName])
                                ? [...user.claims[f.fieldName]]
                                : [user.claims[f.fieldName]])
                        : [];
                    return { ...f, values: newValues }
                });
            setFilters(newFilters)
            setSelectedUser(user);
        },
        [setVisible, setSelectedUser, filters]
    );

    const onAddClick =  useCallback(
        () => {
            const user: UserType = { id: 0, name:'', phone:'', email: '', claims: {admin: false}};

            setSelectedUser(user);

            setFilters(initialFilters);

            setVisible(true);
        },
        [setVisible, setFilters, setSelectedUser]
    );

    const updateFilters = useCallback(
        (newFilters:  Array<FilterType>) => {
            setFilters(newFilters);
        },
        [setFilters]
    );

    const updateSelectedUser = useCallback(
        (user) => {

            setSelectedUser(user);
        },
        [setSelectedUser]
    );

    return <>
        <UserEdit title="Параметры Пользователя"
                  visible={visible}
                  onOk={onEditOk}
                  onCancel={onEditCancel}
                  user={selectedUser}
                  filters={filters}
                  updateFilters={updateFilters}
                  updateSelectedUser={updateSelectedUser}
                  />
        <Popconfirm
            title={"Вы уверены, что хотите удалить пользователя?"}
            visible={!!deleteUserId}
            onConfirm={onConfirm}
            onCancel={onCancel}
            arrowPointAtCenter={true}
        >
            <div style={{position: "fixed", left: "50%", top: "50%"}}/>
        </Popconfirm>
        <Button
            onClick={onAddClick}
            type="primary"
            size="large"
        >
            <Icon type="user-add" />
            Добавить
        </Button>
        <Table
            pagination={false}
            dataSource={data}
            rowKey="id"
            onRowClick={onRowClick}
        >
            <Table.Column
                title="Ид"
                dataIndex="id"
                width="20px"
                sorter={(a, b) => parseInt(a.id) - parseInt(b.id)}
                render={r => <span style={{fontSize: 14}}>{parseInt(r)}</span>}
            />
            <Table.Column
                dataIndex="email"
                width="200px"
                title="Почта"
                sorter={(a, b) => a.email.localeCompare(b.email)}
                render={v => <span style={{fontSize: 14}}>{v}</span>}
            />
            <Table.Column
                dataIndex="name"
                width="200px"
                title="Имя"
                sorter={(a, b) => a.name.localeCompare(b.name)}
                render={v => <span style={{fontSize: 14}}>{v}</span>}
            />
            <Table.Column
                dataIndex="phone"
                width="200px"
                title="Телефон"
                sorter={(a, b) => a.phone.localeCompare(b.phone)}
                render={v => <span style={{fontSize: 14}}>{v}</span>}
            />
            <Table.Column
                width="20px"
                title=""
                render={({id}) => <Button onClick={(e) => {e.stopPropagation(); e.preventDefault();setDeleteUserId(id)}}><Icon type="delete" /></Button> }
            />
        </Table>
    </>
}