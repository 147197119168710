import { loadUsersData } from './loadData';
import api from '../../api';

export const USERS_LOAD_START = 'USERS_LOAD_START';
export const USERS_LOADED = 'USERS_LOADED';
export const ACTIVE_USER_NOT_FOUND = 'ACTIVE_USER_NOT_FOUND';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_STARTED = 'UPDATE_USER_STARTED';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

const defaultState = {
    users: [],
    updating: false,
    error: false
}

export const usersReducer = (state = defaultState, action) => {
    const {payload} = action;
    switch(action.type) {
        case USERS_LOAD_START:
            return defaultState
        case USERS_LOADED:
            return {
                ...state,
                users: payload,
            }
        case ACTIVE_USER_NOT_FOUND:
            return {
                ...state,
                error: "User not found",
            }
        case DELETE_USER:
            const filteredUsers = state.users.filter(u => u.id !== payload.userId)
            return {
                ...state,
                users: filteredUsers,
                updating: false,
            }
        case UPDATE_USER:

            const userIndex = state.users.findIndex(u => {
                return u.id === payload.user.id
            });

            const usersUpdated = [...state.users];

            if (userIndex >= 0)
                usersUpdated[userIndex] = {...payload.user};
            else
                usersUpdated.push(payload.user);

            return {
                ...state,
                users: usersUpdated,
                updating: false
            }

        case UPDATE_USER_STARTED:
            return {
                ...state,
                updating: true,
                error: false,
            }
        case UPDATE_USER_FAILED:
            return {
                ...state,
                updating: false,
                error: payload.error,
            }
        default:
            return state
    }
}

/**
 *
 * @param {Number} userId
 * @param {Array.<any>} users 
 */
const findUser = (userId, users) => {
    const candidates = users.filter(d => {
        return d.id === userId
    });
    if (candidates.length > 0) {
        return candidates[0];
    } else {
        return null;
    }
}

export const updateUser = (updateData) => {
    return ( dispatch, getState ) => {
        const { usersReducer } = getState();
        const activeUser = findUser(updateData.id, usersReducer.users);
        if (activeUser || updateData.id === 0) {
            dispatch({
                type: UPDATE_USER_STARTED
            })
            api.users.update(updateData)
                .then(({ data }) => {
                    dispatch({
                        type: UPDATE_USER,
                        payload: {
                            user: data,
                        }
                    })
                })
                .catch(e => {
                    dispatch({
                        type: UPDATE_USER_FAILED,
                        payload: {
                            error: e
                        }
                    })
                })
        } else {
            dispatch({type: ACTIVE_USER_NOT_FOUND})
        }
    }
}

export const deleteUser = (userId) => {
    return ( dispatch, getState ) => {
        const { usersReducer } = getState();
        const activeUser = findUser(userId, usersReducer.users);
        if (activeUser) {
            dispatch({
                type: UPDATE_USER_STARTED
            })
            const updateData = {
                id: activeUser.id,
                claims: {...activeUser.claims, disable: true}
            }
            api.users.update(updateData)
                .then(({ data }) => {
                    dispatch({
                        type: DELETE_USER,
                        payload: {
                            userId,
                        }
                    })
                })
                .catch(e => {
                    dispatch({
                        type: UPDATE_USER_FAILED,
                        payload: {
                            error: e
                        }
                    })
                })
        } else {
            dispatch({type: ACTIVE_USER_NOT_FOUND})
        }
    }
}
export const loadUsers = (claims) => {
    return dispatch => {
        dispatch({
            type: USERS_LOAD_START
        })
        loadUsersData(claims)
            .then(d => {
                dispatch({
                    type: USERS_LOADED,
                    payload: d,
                });
            })
    }
}
