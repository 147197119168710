import React from 'react';
import {
    ResponsiveContainer, XAxis, YAxis, Tooltip,
    CartesianGrid, BarChart, Bar, Cell
} from 'recharts';
// eslint-disable-next-line no-unused-vars
import moment from 'moment';

import {formatNumber} from '../../utils/format';

/**
 * 
 * @param {{
 *  height: Number,
 *  barsTitle: String,
 *  minValue: 'dataMin' | 'auto' | Number,
 *  maxValue: 'dataMin' | 'auto' | Number,
 *  hideYAxis: Boolean?
 *  data: Array.<{
 *      d: String,
 *      v: Number,
 *      isActive: Boolean,
 *      dateText: String
 *  }>
 * }} props 
 */
const LineGraph = props => {
    return <ResponsiveContainer width="100%" height={props.height}>
        <BarChart data={props.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tickLine={false} dataKey="d" />
            {!props.hideYAxis && <YAxis 
                domain={[
                    props.minValue >= 0 ? props.minValue : props.minValue || 'dataMin', 
                    // 'auto'
                    props.maxValue >= 0 ? props.maxValue : props.maxValue || 'auto', 
                ]} 
                tickLine={false}
                // tickFormatter={formatNumber}
            />}
            <Bar 
                fill="#c8e6c9" 
                dot={false} strokeWidth={2} 
                isAnimationActive={false} type="monotone" 
                dataKey="v" 
            >
                {props.data.map((d, index) => <Cell 
                    key={index}
                    fill={d.isActive ? '#2e7d32' : '#c8e6c9'}
                />)}
            </Bar>
            <Tooltip 
                cursor={{fill: '#00000010'}}
                formatter={(v, n, e) => {
                    return 'ok'
                }}
                content={<CustomTooltip valueTitle={props.barsTitle} />}
                // formatter={v => <span>{formatNumber(v)}</span>} 
            />
        </BarChart>
    </ResponsiveContainer>
}

const CustomTooltip = ({active, payload, label, valueTitle}) => {
    return <div style={{backgroundColor: 'white', padding: 20}}>
        {payload && payload.length > 0 && <div>
            Неделя: <strong>{payload[0].payload.dateText}</strong>
            {payload.map((p, i) => <div key={i}>
                {valueTitle}: <strong>{formatNumber(p.payload.v)}</strong>
            </div>)}
        </div>}
    </div>
}

export default LineGraph;