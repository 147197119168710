import React from 'react';
import { Layout } from 'antd';
import RatingList from '../modules/RatingList';

const { Content } = Layout;

const Top = ({match}) => {
    return <Content
        style={{
            // margin: '24px 16px',
            padding: 24,
            background: '#fff',
            minHeight: 280,
        }}
    >
        <RatingList />
    </Content>
}

export default Top;